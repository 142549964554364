import React, {Component} from 'react';

class FooterForm extends Component{
    render(){
        return(
            <footer id="footer">
            <div className="footer-top">
                <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                    <div className="footer-info">
                        <h3>Mservice</h3>
                        <p>
                        <strong>Phone:</strong> 0812-8109-4975<br />
                        <strong>Email:</strong> mservice.meikarta@gmail.com<br />
                        </p>
                        <div className="social-links mt-3">
                        <a  className="whatsapp" href='https://bit.ly/InfoLayananMService'><i className="bx bxl-whatsapp" /></a>
                        <a  className="facebook" href='https://www.facebook.com/MSERVICE.MEIKARTA/'><i className="bx bxl-facebook" /></a>
                        <a  className="instagram" href='https://www.instagram.com/mservicemeikarta/'><i className="bx bxl-instagram" /></a>
                        {/* <a  className="google-plus"><i className="bx bxl-skype" /></a>
                        <a  className="linkedin"><i className="bx bxl-linkedin" /></a> */}
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Our Services</h4>
                    <ul>
                        <li><i className="bx bx-chevron-right" /> <a href="#" >MService</a></li>
                        <li><i className="bx bx-chevron-right" /> <a href="#">MTeknik</a></li>
                    </ul>
                    </div>
                    <div className="col-lg-4 col-md-6 footer-newsletter">
                    <h4>Our Newsletter</h4>
                    <p>Don't miss our news and promotion. </p>
                    <form >
                        <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
                    </form>
                    </div>
                </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                © Copyright <strong><span>MService</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                Designed by <a href="https://Mservice.id/">MService IT Dept</a>
                </div>
            </div>
            </footer>

        )
    }
}

export default FooterForm;