import { Navbar, Nav, Container } from "react-bootstrap"
import Logo from "../../Logo.jpeg"; 


const AdminMenu =()=>{
return(
<Navbar bg="light" expand="lg">
  <Container>
    <Navbar.Brand href="#home">
        <img
            src={Logo}
            height="30"
            className="d-inline-block align-top"
            alt="<Mservice>"
        />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="../order/admin2">Transaction</Nav.Link>
        <Nav.Link href="../order/admin/user">User</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

)}
export default AdminMenu