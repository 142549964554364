import React, { Component } from 'react';
import './loginForm.css';
import {reactLocalStorage} from 'reactjs-localstorage';
import {connect } from 'react-redux';
import { loginUserAPI, registerUserAPI } from '../config/redux/action';
import Button from './atoms';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2'





class LoginForm extends Component{
    state ={
        regname:'',
        reghandphone:'',
        regpass:'',
        regunit:'',
        confirmpass:'',
        loginphone:'',
        loginpass:'',
        isLoading: false

    }

componentDidMount(){
    reactLocalStorage.remove('USERS_CACHE');
}

    handleChangeText = (e) =>{
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    Register = async () =>{
        //console.log('name ',regname, ' reghandphone ', reghandphone, ' regpass ', regpass,' regunit ',regunit)
        if(this.state.confirmpass===this.state.regpass){
            const res = await this.props.registerUser({     
                regname: this.state.regname,
                reghandphone: this.state.reghandphone,
                regpass: this.state.regpass,
                regunit: this.state.regunit
             }).catch(err=>err)
                 
             if(res){
                Swal.fire('Registrasi berhasil', '', 'success')
                 this.setState({loginphone:this.state.reghandphone,
                    loginpass:this.state.regpass})
                this.handleLogin()
                
                           
            }else{
                Swal.fire({
                    title: 'registrasi gagal, Nomor handphone anda telah terdaftar sebelumnya. silahkan login atau hubungi admin',
                    showDenyButton: true,
                    confirmButtonText: 'Hubungi CS',
                    denyButtonText: 'OK',
                    }).then((result) => {
                    if (result.isConfirmed) {
                        window.open("https://wa.me/+6281281094975?text=Hi%20kakak%2C%20saya%20mengalami%20error%20saat%20registrasi%20web%20mservice.%0Atolong%20dibantu%20ya")
                    } else if (result.isDenied) {
                        
                    }
                    })
            }
                
    }else{
        Swal.fire('password tidak sama', '', 'info')
    }
    }
    // handleLogin = async () =>{
    //     const loginEmail = this.state.loginphone+'@mservice.com';
    //     const loginPassword = this.state.loginpass;
    //     const {history} = this.props
    //     //console.log('tau lah')
    //     const res = await this.props.loginUser({email : loginEmail, password: loginPassword}).catch(err=>err);
    //     if(res){
    //         console.log('login success')
    //         this.setState({
    //             reghandphone: '',
    //             regpass : ''
    //         })
            
    //         history.push('/');        
    //     }else{
    //         console.log('login fail')
    //     }
    // }

    handleLogin = async () =>{
        const loginHandphone = this.state.loginphone;
        const loginPassword = this.state.loginpass;
        const {history} = this.props
        //console.log('tau lah')
        const res = await this.props.loginUser({handphone : loginHandphone, password: loginPassword}).catch(err=>err);
        if(res){
            console.log(res);
            reactLocalStorage.setObject('USERS_CACHE', {
                'uid': res.data.uid,
                'name': res.data.name,
                'handphone': res.data.handphone,
                'address': res.data.address
            });
            this.setState({
                loginphone: '',
                loginpass : ''
            })
            const users = reactLocalStorage.getObject('USERS_CACHE');
           console.log(users)
            history.push({
                pathname: '/',
                search: '?user='+users.name,
                state: { detail: users }
            });        
        }else{
            alert('username atau password salah');
            console.log('login fail')
        }
    }

    
    render(){
        return(
           <div className="row">
            <div className="col-md-6 mx-auto p-0">
                <div >
                <div className="login-box">
                    <div className="login-snip"> <input id="tab-1" type="radio" name="tab" className="sign-in"  /><label htmlFor="tab-1" className="tab">Login </label> <input id="tab-2" type="radio" name="tab" className="sign-up" defaultChecked/><label htmlFor="tab-2" className="tab">Sign Up</label> 
                    <div className="login-space">
                        <div className="login">
                        <div className="group"> <label htmlFor="user" className="label">No Handphone</label> <input id="loginphone" onChange={this.handleChangeText} type="text" className="input" placeholder="Masukan no handphone" /> </div>
                        <div className="group"> <label htmlFor="pass" className="label">Password</label> <input id="loginpass" onChange={this.handleChangeText} type="text" className="input" placeholder="Masukan password" /> </div>
                        <div className="group"> <input id="check" type="checkbox" className="check" defaultChecked /> <label htmlFor="check"><span className="icon" /> Keep me Signed in</label> </div>
                        <div className="group"> <Button onClick={this.handleLogin} title='Login' className="button" loading={this.props.isLoading}/> </div>
                        <div className="hr" />
                        {/* <div className="foot"> <a href="#">Forgot Password?</a> </div> */}
                        </div>
                        <div className="sign-up-form">
                        <div className="group"> <label htmlFor="user" className="label">Nama</label> <input id="regname" onChange={this.handleChangeText} type="text" className="input" placeholder="Masukan nama anda" /> </div>
                        <div className="group"> <label htmlFor="user" className="label">No Handphone / WA</label> <input id="reghandphone" onChange={this.handleChangeText} type="text" className="input" placeholder="Masukan no handphone" value={this.state.reghandphone}/> </div>
                        <div className="group"> <label htmlFor="pass" className="label">Unit </label> <input id="regunit" onChange={this.handleChangeText} type="text" className="input" placeholder="Masukan alamat unit" /> </div>
                        <div className="group"> <label htmlFor="pass" className="label">Password</label> <input id="regpass" onChange={this.handleChangeText} type="text" className="input"  placeholder="Masukan password" value={this.state.regpass} /> </div>
                        <div className="group"> <label htmlFor="pass" className="label">Repeat Password</label> <input id="confirmpass" onChange={this.handleChangeText} type="text" className="input"  placeholder="ulangi password" /> </div>
                        <div className="group"> <Button onClick={this.Register} title='register' className="button" loading={this.props.isLoading}   /> </div>
                        <div className="hr" />
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

        )
    }
}



const reduxState = (state) =>({
    isLoading : state.isLoading,
    popupProps: state.popup,
    userName : state.user
})

const reduxDispatch = (dispatch) =>({
    registerUser: (data) => dispatch(registerUserAPI(data)),
    loginUser: (data) => dispatch(loginUserAPI(data))
})

export default connect(reduxState, reduxDispatch)(withRouter(LoginForm));