import React, { Component, Fragment } from 'react';
import axios from 'axios';
import './order.css';
import '../component/modalForm.css';
import MenuAdminForm from '../component/menuadmin';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import date from 'date-and-time';
import { Table } from 'react-bootstrap';



class AdminVendorPage extends Component{
     state={
         showModal: false,
        listtrans:{},
        delivdate:'',
        order:[],
        confirm:false,
        fromdate:'',
        todate: '',
        typetrans:'',
        vendor:''

    } 
   modal='ok'


   
    componentDidMount = async()=>{
        await Swal.fire({
            title: 'Service selection',
            input: 'select',
            allowOutsideClick: false,
            inputOptions: {
                laundry:'laundry',
                gasmin:'gasmin'
            },
            inputPlaceholder: 'Select a service',
            inputValidator: (value) => {
              return new Promise((resolve) => {
                  this.setState({vendor:value})
                if (value !== '') {
                    Swal.fire({
                        title: 'Loading data '+value,
                        html: 'please wait ..',
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            let parameter = {fromdate : '', todate: '', vendor:value}
                            axios.get('/order/admin/transaction2',{params:parameter})
                            .then((trans)=>{
                                console.log('ini adalah',trans)
                                this.setState({
                                    listtrans: trans.data
                                })  
                                //console.log('ini apa:', trans.data)
                                Swal.close()
                            })
                          
                        }})
                } else {
                  resolve('please select the service first :)')
                }
              })
            }
          })
        

         
            
            // Swal.fire({
            //     title: 'Please Wait !',
            //     html: 'Collecting data '+vendor,// add html attribute if you want or remove
            //     allowOutsideClick: false,
            //     onBeforeOpen: () => {
            //         Swal.showLoading()
            //         // axios.get('/order/admin/alltransaction2')
            //         // .then((trans)=>{
            //         //     //console.log('ini adalah',trans)
            //         //      this.setState({
            //         //          listtrans: trans.data
            //         //      })  
            //         //      Swal.close()
            //         // })

            //     },
            // });
            
          
        
         if (this.props.users.handphone !== '' || this.props.users.handphone !== undefined){
             alert('admin harus login terlebih dahulu');
             const {history} = this.props
            history.push({
                pathname: '/login'
            });   
        }
          
        
        
    } 

    handleOpenModal =() =>{
        this.setState(
            { showModal: true }
            );
      }

      handleCloseModal =() => {
          console.log('ini', this.state.showModal)
        this.setState({ showModal: false });
      }

      handleDelivDate = (e) =>{
          this.setState({
              delivdate: e.target.value
          })//,()=>console.log(this.state.delivdate))
          
          }

    
    handleChangeText = (e) =>{
            this.setState({
                [e.target.id]:e.target.value
            })
        //console.log(this.state.fromdate,'----',this.state.typetrans)
        }
   

    HandleFilter=()=>{
        if(this.state.fromdate <= this.state.todate) { 
            let parameter = {fromdate : this.state.fromdate, todate: this.state.todate, vendor:this.state.vendor}
            console.log('params ', parameter)
            Swal.fire({
                title: 'Loading data ',
                html: 'please wait ..',
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    axios.get('/order/admin/transaction2', {params:parameter})
                    .then((trans)=>{
                    //console.log('ini adalah',trans)
                     this.setState({
                         listtrans: trans.data
                     })  
                     Swal.close()
                })
                }})
           
        }else{ console.log('salah')}

    }

    getzonedate=(tanggal)=>{
        let newdate = new Date(tanggal);
        newdate.setHours(newdate.getHours())
        //console.log(newdate)
        return date.format(newdate,'YYYY-MM-DD HH:mm:ss')
    }

    setDateRange=(update)=>{
        this.setState({fromdate:update[0]})
        this.setState({todate:update[1]})
    }

    setWeight= async(selected) =>{
        Swal.fire({
            title: 'berat total:',
            input: 'text',
            inputLabel: 'Masukan berat dalam Kg',
           
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value || value < 4) {
                  console.log('selected: ',selected)
                return 'berat tidak boleh kosong atau dibawah 4 Kg '
              }else{
                  let params = {
                    transid : selected.transid ,
                    packageid : selected.var1,
                    weight : value

                  }
                  axios.post('/order/admin/laundry/updateweight', params)
                     .then(()=>{
                        Swal.fire({
                            
                            icon: 'success',
                            title: 'Berat sudah di update',
                            showConfirmButton: false,
                            timer: 1000
                          })
                          let parameter = {fromdate : this.state.fromdate, todate: this.state.todate, vendor:this.state.vendor}
                          axios.get('/order/admin/transaction2',{params:parameter})
                          .then((trans)=>{
                              //console.log('ini adalah',trans)
                              this.setState({
                                  listtrans: trans.data
                              })  
                              //console.log('ini apa:', trans.data)
                          })
                        
                 })
                
              }
            }
          })
    }
   

    
    
    render(){   

        return(
            <div class="d-flex justify-content-center">
           
            {/* <MenuAdminForm/>
            <div className="input-daterange input-group" id="datepicker">
            <input type="text" className="input-sm form-control" name="start" />
            </div> */}

            
                {/* <section className="content"> */}
                <div className="row" >
                    <div className="col-12">
                    <div>
                        <div className="card-header">
                            <h3 className="card-title">Transaction</h3>
                        </div>
                        <div className="row m-2 justify-content-md-end">
                                
                                <div class="col col-md-auto">
                                    <label>Date range:</label>  
                                </div>
                                    {/* <div className='col-4'> <DatePicker selected={this.state.fromdate}  onChange={(date) => this.setState({fromdate:date})} /> </div> 
                                    <div className='col-1'> to </div>
                                    <div className='col-4'><DatePicker selected={this.state.todate}  onChange={(date) => this.setState({todate:date})} /> </div> */}
                                <div class="col col-md-auto">       
                                    <DatePicker
                                        todayButton="Today"
                                        selectsRange={true}
                                        startDate={this.state.fromdate}
                                        endDate={this.state.todate}
                                        onChange={(update) => {
                                            this.setDateRange(update);
                                        }}
                                        />
                                </div>  
                                <div class="col col-md-auto "> 
                                    <button type="submit" className="btn btn-success btn-block btn-sm" onClick={this.HandleFilter} >filter</button>
                                </div>
                    

                    {/* <div className="form-group col-md-1">

                    <label>type transaction</label>
                            <div className="input-group">
                                <select class="" id="typetrans"  onChange={this.handleChangeText}  >
                                <option default value="all">All transaction</option>
                                <option value="gasmin">Gas Mineral</option>
                                <option value="laundry">Laundry</option>
                                <option value="cathering">Cathering</option>
                                <option value="delivery">Delivery</option>
                                </select>
                                <div>
                    </div>
                            </div>
                    </div>
                    */}


                    

                    </div>

                    <div>
                       
                        <Table striped bordered hover size="sm"  >
                            
                    
                            <thead>
                            <tr>
                                {/* <th>transdate</th> */}
                                <th>name</th>
                                {/* <th>handphone</th> */}
                                <th>unit</th>
                                {/* <th>category</th> */}
                                <th>pickup</th>
                                {/* <th>deliverydate</th> */}
                                <th>item</th>
                                <th>weight</th>
                                {/* <th>price</th> */}
                                {/* <th>notes</th> */}
                                <th>action</th>
                                </tr>
                            </thead>
                            <tbody className='justify-content-center'>
                            {Object.entries(this.state.listtrans)
                            .map(([key,value])=>(
                                    <tr>
                                        {this.tanggal = value.transdate}
                                        {/* <td><small>{this.getzonedate(value.transDate)}</small></td> */}
                                        <td><small>{value.name}</small></td>
                                        {/* <td><small>{value.handphone}</small></td> */}
                                        <td><small>{value.unit}</small></td>
                                        {/* <td><small>{value.category}</small></td> */}
                                        <td><small>{this.getzonedate(value.pickupDate).substring(0, 11)}</small></td>
                                        {/* <td><small>{this.getzonedate(value.deliveryDate)}</small></td> */}
                                        <td><small>{value.item}</small></td>
                                        <td><small>{value.quantity}</small></td>
                                        {/* <td><small>{value.itemprice}</small></td> */}
                                        {/* <td><small>{value.notes}</small></td>   */}
                                        <td>{(value.var1 >= 1)?<small><button className='btn btn-primary btn-xs mt-1 mb-1' onClick={this.setWeight.bind(this,value)}>update</button></small> :""}</td>                                      
                                    </tr>
                                        
      
                                )
                        )}
                            
                            </tbody>
                                
                        </Table>
                        </div>
                    </div>
                    </div>
                </div>
                    {/* </section> */}

                  
            </div>
        )
    }
}

export default AdminVendorPage;