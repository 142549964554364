import React, {Component}  from 'react';
import { Fragment } from 'react';
import FooterForm from '../component/footerForm';
import MainForm from '../component/mainForm';
import MenuForm from '../component/menu';
import { withRouter } from "react-router";

class MainPage extends Component{
 
  render(){
    
    let nama
    try{
    (this.props.location.state.detail.name)? nama=this.props.location.state.detail.name : nama=this.props.users.name;
    }catch{
      nama=this.props.users.name
    }
      return(
        <Fragment>
        <MenuForm name={nama} />
        <MainForm />
        <FooterForm />
        </Fragment>  
      )
    }
  }

  export default withRouter(MainPage);


  