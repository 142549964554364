import React from "react"

const Button =({title,onClick,className, loading}) => {
    if (loading){
        return (
            <button className='disable' disabled='disable'>loading..</button>  
        )
    }
    return(
        <button className={className} onClick={onClick} > {title} </button>
    )
}
export default Button;