import React, { Component, Fragment } from 'react';
import axios from 'axios';
import '../order.css';
import '../../component/modalForm.css';
//import MenuAdminForm from '../../component/menuadmin';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import date from 'date-and-time';
import AdminMenu from './adminMenu';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


class UserAdminPage extends Component{
    constructor(props) {
        super(props)
        
      }
     state={
         showModal: false,
        listuser:{
        },
        delivdate:'',
        showuser:[],
        confirm:false,
        fromdate:'',
        todate: '',
        typetrans:'',
        vendor:''
    } 
   modal='ok'

   loginForm=()=>{
        Swal.fire({
            title: 'Login Form',
            html: `<input type="text" id="login" class="swal2-input" placeholder="Username">
            <input type="password" id="password" class="swal2-input" placeholder="Password">`,
            confirmButtonText: 'Sign in',
            focusConfirm: false,
            preConfirm: () => {
            const login = Swal.getPopup().querySelector('#login').value
            const password = Swal.getPopup().querySelector('#password').value
            if (!login || !password) {
                Swal.showValidationMessage(`Please enter login and password`)
            }
            return { login: login, password: password }
            }
        }).then((result) => {
            axios.post('/order/admin/cekadmin',{params:result})
            .then((login)=>{
                console.log(login.data)
                if(login.data==='4dm1n'){
                    Swal.fire({
                        title: 'Loading data ',
                        html: 'please wait ..',
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            
                            //console.log(parameter)
                            axios.post('/order/admin/usergetmanage',{xnpm:"p02p02p02"})
                            .then((user)=>{
                                console.log('ini adalah',user)
                                this.setState({
                                    listuser: user.data
                                })  
                                
                                // Object.entries(this.state.listuser)
                                //     .map(([key,value])=>(
                                //         this.setState(previousState => ({
                                //             showuser: [...previousState.showuser, value]
                                //         }))
                                //         //console.log('ini key :', key ," ini value : ",value)
                                //     ))
                                
                                this.showUserTable()


                                Swal.close() 
                                return ""
                            })
                          
                        }})
                   

                }else{
                    Swal.fire({
                        title:'error',
                        text:'username atau password salah',
                        icon:'error'
                        
                    }).then(()=>{
                        Swal.close()
                        this.loginForm()
                    })
                    
                }   
                
            })
        
      })

   }

   showUserTable=()=>{
       this.setState({showuser:[]})
    Object.entries(this.state.listuser)
    .map(([key,value])=>(
        this.setState(previousState => ({
            showuser: [...previousState.showuser, value]
        }))
        //console.log('ini key :', key ," ini value : ",value)
    ))
   }

    componentDidMount= async() =>{

        //this.setState({showuser:this.state.listuser})
        //console.log("ini lust user: ",this.state.listuser)
        await this.loginForm()
        
    } 

    handleChangeText = (e) =>{
            this.setState({
                [e.target.id]:e.target.value
            })
        //console.log(this.state.fromdate,'----',this.state.typetrans)
        }
   

    HandleFilter=()=>{
        if(this.state.fromdate <= this.state.todate) { 
            let parameter = {fromdate : this.state.fromdate, todate: this.state.todate,vendor:'all'}
            Swal.fire({
                title: 'Loading data ',
                html: 'please wait ..',
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    console.log(parameter)
                    axios.get('/order/admin/transaction2',{params:parameter})
                    .then((trans)=>{
                        //  console.log('ini adalah',trans)
                        this.setState({
                            listuser: trans.data
                        })  
                        Swal.close()
                    })
                  
                }})
            // console.log('params ', parameter)
            // axios.get('/order/admin/transaction2', {params:parameter})
            //     .then((trans)=>{
            //     console.log('ini adalah',trans)
            //      this.setState({
            //          listuser: trans.data
            //      })  
            // })
        }else{ console.log('salah')}

    }

    getzonedate=(tanggal)=>{
        if (tanggal === null){
            return null
        } else{
        let newdate = new Date(tanggal);
        newdate.setHours(newdate.getHours())
        //console.log(newdate)
        return date.format(newdate,'YYYY-MM-DD HH:mm:ss')
    }
    }

    delivpickdate=(tanggal)=>{
        if (tanggal === null){
            return null
        } else{
        let newdate = new Date(tanggal);
        //console.log( 'datanya',newdate)
        return  newdate.toISOString().replace('Z', ' ').replace('T', ' ').replace('.000','')
        }
    }

    setDateRange=(update)=>{
        this.setState({fromdate:update[0]})
        this.setState({todate:update[1]})
    }

    editData = async(val) =>{
        console.log(val)
    }
          
        
   

    deleteData = async (val) =>{
        Swal.fire({
            title: 'Kamu yakin?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus '+val.transid+'!'
          }).then((result) => {
            if (result.isConfirmed) {
                //param = val
               // await axios
              Swal.fire(
                'Deleted!',
                '',
                'success'
              )
            }
          })
    }

    onFollowChanged= async(row)=> {
        console.log('isi rownya',row)
        const { value: formValues } = await Swal.fire({
            title: 'Update data user',  
            showCancelButton: true, 
            html:
            '<div><label>Userid :</label><input id="swal-input1" class="swal2-input" readonly value='+row.uid+'><div>' +
            '<div><label>name :</label><input id="swal-input2" class="swal2-input" value="'+row.name+'"><div>' +
            '<div><label>unit :</label><input id="swal-input3" class="swal2-input" value="'+row.address+'"><div>' +
            '<div><label>handphone :</label><input id="swal-input4" class="swal2-input" value='+row.handphone+'><div>', 
            //'<input id="swal-input2" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
                let parameter = {userid: document.getElementById('swal-input1').value,
                                name : document.getElementById('swal-input2').value,
                                address : document.getElementById('swal-input3').value,
                                handphone : document.getElementById('swal-input4').value

                } 

//              return (
                // document.getElementById('swal-input1').value,
                // document.getElementById('swal-input2').value,
                // document.getElementById('swal-input3').value,
                // document.getElementById('swal-input4').value
                if(parameter.userid ==='' || parameter.name ==='' || parameter.handphone ==='' || parameter.address === '' ){
                    Swal.fire(
                        'all field must filled',
                        '',
                        'error'
                      )
                }else{
                    axios.post('/order/admin/updateuser',{params:parameter})
                    .then((result)=>{
                        axios.post('/order/admin/usergetmanage',{xnpm:"p02p02p02"})
                        .then((user)=>{
                            //console.log('ini adalah',user)
                            this.setState({
                                listuser: user.data
                            })
                        }  
                        )
                        
                    }
                    
                    )
                }
  //            )
            }
          })
        
            if (formValues) {
            await Swal.fire('Data telah terupdate',
            '',
            'success')
            .then( 
                this.showUserTable()
            )
          }
          this.showUserTable()          
    
      }

    linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (

            <h5><div  onClick={() => {
                this.onFollowChanged(row);
              }}
              ><FontAwesomeIcon icon={faEdit} /></div></h5>
         
        );
      };
    
    render(){   
        const { SearchBar } = Search;
        const columns = [{
            dataField: 'name',
            text: 'Name'
          }, {
            dataField: 'handphone',
            text: 'handphone'
          },{
            dataField: 'address',
            text: 'unit'
          },
          {
            dataField: "",
            text: "Follow",
            formatter: this.linkFollow
          }];

        
        
        return(
            <div className="d-flex justify-content-center">
           
            {/* <MenuAdminForm/>
            <div className="input-daterange input-group" id="datepicker">
            <input type="text" className="input-sm form-control" name="start" />
            </div> */}

            
                {/* <section className="content"> */}
                <div className="row" >
                    <div className="col-12">
                    <div>
                       <AdminMenu></AdminMenu>

                       {/* <div className="row m-2 justify-content-md-end">
        
                            <div className="col col-md-auto">
                                <label>Date range:</label>  
                            </div>
                            <div className="col col-md-auto">       
                                <DatePicker
                                    todayButton="Today"
                                    selectsRange={true}
                                    startDate={this.state.fromdate}
                                    endDate={this.state.todate}
                                    onChange={(update) => {
                                        this.setDateRange(update);
                                    }}
                                    />
                            </div>  
                            <div className="col col-md-auto "> 
                                <button type="submit" className="btn btn-success btn-block btn-sm" onClick={this.HandleFilter} >filter</button>
                            </div>

                        </div>` */}
                    <div>
                       
                        {/* <Table striped bordered hover size="sm"  >
                            
                    
                            <thead>
                            <tr>
                                <th>userid</th>
                                <th>Name</th>
                                <th>handphone</th>
                                <th>unit</th>
                                
                                 <th>action</th> 
                                
                                </tr>
                            </thead>
                            {console.log('user :',this.state.listuser)}
                            <tbody className='justify-content-center'>
                            {Object.entries(this.state.listuser)
                            .map(([key,value])=>(
                                    <tr>
                                        <td><small>{value.uid}</small></td>
                                        <td><small>{value.name}</small></td>
                                        <td><small>{value.handphone}</small></td>
                                        <td><small>{value.address}</small></td>
                                        <td><h5><div onClick={this.editData.bind(this,value)}><FontAwesomeIcon icon={faEdit} /></div> <div onClick={this.deleteData.bind(this,value)}><FontAwesomeIcon icon={faTrashCan} /></div></h5></td>
                                    </tr>
                                        
      
                                )
                        )}
                            
                            </tbody>
                                
                        </Table>
                         */}

                         

                         
                        </div>
                        <ToolkitProvider
                        keyField="userid"
                        data={ this.state.showuser }
                        columns={ columns }     
                        search
                        >
                         {
                            props => (
                            <div> 
                                <hr />
                               <div className='d-flex justify-content-end'>
                                <SearchBar { ...props.searchProps } />
                               </div>
                                <hr />
                                <BootstrapTable
                                { ...props.baseProps }
                                />
                            </div>
                            )
                        }
                        </ToolkitProvider>
                    </div>
                    </div>
                </div>
                    {/* </section> */}

                  
            </div>
        )
    }
}

export default UserAdminPage;