import React, { Component, Fragment } from "react";
import LoginForm from "../component/loginForm";


class LoginPage extends Component{
    render(){
        return( 
            <Fragment>
                
                <LoginForm />
            </Fragment>

        )
    }
}

export default LoginPage;