import React, { Component } from 'react';
import './order.css';
import date from 'date-and-time'
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

class DeliveryPage extends Component{
    state={
        penerima : {nama:'',
                    phone:'',
                    alamat: '',
                    kecamatan: '',
                    kabupaten: '',
                    kodepos:'',
                    isi:'',
                    jumlah:'',
                    harga:'',
                    },
        valuedate:'',
        pickupdate:'',
        comment:''
    }
    
    currenttime = date.format(new Date(),'HH:mm:ss')

    converttorupiah = (value)=>{
        const format = value.toString().split('').reverse().join('');
        const convert = format.match(/\d{1,3}/g);
        const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
        return rupiah
    }
    confirmOrder =()=>{
        Swal.fire({
            html: `<h2>Konfirmasi Penerima</h2> 
                    <table class="text-start">
                    <tr><td> Pickup</td><td>: `+this.state.valuedate+`</td></tr>
                    <tr><td> Nama</td><td>: `+this.state.penerima.nama+`  </td></tr>
                    <tr><td> Phone</td><td>: `+this.state.penerima.phone+` </td></tr>
                    <tr><td> Alamat</td><td>: `+this.state.penerima.alamat+` </td></tr>
                    <tr><td> Kecamatan</td><td>: `+this.state.penerima.kecamatan+` </td></tr>
                    <tr><td> Kabupaten</td><td>: `+this.state.penerima.kabupaten+` </td></tr>
                    <tr><td> Kodepos</td><td>: `+this.state.penerima.kodepos+` </td></tr>
                    <tr><td> Isi</td><td>: `+this.state.penerima.isi+` </td></tr>
                    <tr><td> Jumlah isi</td><td>: `+this.state.penerima.jumlah+` </td></tr>
                    <tr><td> Harga barang</td><td>: `+this.state.penerima.harga+`</td></tr>
                     <tr><td> Note</td><td>: `+this.state.comment+`</td></tr>
                    
                    </table> `,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText:'perbaiki',
                    confirmButtonText: 'Konfirm'
        }).then((result)=>{
            if (result.isConfirmed) {
                let parameter ={
                    userid : this.props.users.uid,
                    penerima: this.state.penerima,
                    pickupdate: this.state.pickupdate,
                    comment: this.state.comment
                }
                console.log('confirmed')
                axios.post('/delivery/trans',parameter)
                .then(()=>{
                       Swal.fire(
                            'Terima Kasih',
                            'Tekan tombol "OK" untuk confirmasi pesanan via WA',
                            'success'
                        ).then(()=>{
                            let vendornya = ''
                            const {history} = this.props
                            let pesanan = ''
                            pesanan += '%0Anama%20%3A%20'+ this.props.users.name
                            pesanan += '%0Aunit%20%3A%20'+ this.props.users.address
                            pesanan += '%0APhone%20%3A%20' + this.props.users.handphone
                            pesanan += '%0APickup%20date%20%3A%20' + this.state.valuedate
                            pesanan += '%0A=====Tujuan paket====== %20%3A%20'
                            // Object.entries(this.state.penerima).map((key,value)=>{
                                //if(key[1].length > 0){
                                    //console.log('ini key :', key)
                                        //Object.entries(key[1].product)
                            pesanan +=  '%0A%20Nama%20Penerima%20%3A%20'+this.state.penerima.nama
                            pesanan +=  '%0A%20Phone%20%3A%20'+this.state.penerima.phone
                            pesanan +=  '%0A%20Alamat%20%3A%20'+this.state.penerima.alamat
                            pesanan +=  '%0A%20Kecamatan%20%3A%20'+this.state.penerima.kecamatan
                            pesanan +=  '%0A%20Kabupaten%20%3A%20'+this.state.penerima.kabupaten
                            pesanan +=  '%0A%20Kodepos%20%3A%20'+this.state.penerima.kodepos
                            pesanan +=  '%0A%20Isi%20Paket%20%3A%20'+this.state.penerima.isi
                            pesanan +=  '%0A%20Jumlah%20%3A%20'+this.state.penerima.jumlah
                            pesanan +=  '%0A%20Nilai%20Barang%20%3A%20'+this.converttorupiah(this.state.penerima.harga)
                                        
                            //}
                            // })
                           // pesanan += '%0ATotal harga%20%20%20%20%20%20%20%20%20%3A%20'+ this.converttorupiah(this.state.totalharga - 5000)
                            // pesanan += '%0AService fee%20%20%20%20%20%20%20%20%20%3A%20 Rp. 5.000'
                           

                            pesanan += '%0ANotes%20%3A%20'+this.state.comment

                            window.open("https://wa.me/+6281311699098?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice."+
                            pesanan+'%0Atolong%20segera%20di%20proses%20ya', '_blank', 'noopener,noreferrer')

                            
                            history.push({
                                pathname: '/'
                            });
                        })
                         
                })
                .catch((error)=>{
                    console.log(error)
                })
            }
        })
    }

    checkPenerima = () =>{
        let terisi=''
        
        Object.entries(this.state.penerima).map((key,value)=>{
            if(key[1]===''){
                return terisi='ng'
            }
            //console.log('ini key :', key[0] ,' ini value :', key[1])
        })

        if(terisi === 'ng'){
            Swal.fire(
                '',
                'ada data yang belum terisi, harap periksa kembali',
                'warning'
            )
           
        }else if (this.state.pickupdate === ''){
                Swal.fire(
                    '',
                    'anda belum memasukan tanggal transaksi',
                    'error'
                )
            }else{
            this.confirmOrder()
        }
    }

    handlePenerimaChange = (e) =>{
        this.setState(prevState=>({
            penerima:{...prevState.penerima,[e.target.id]:e.target.value}
        })
        )
        // console.log(this.state.penerima)
    }

    handleText=(e)=>{
            this.setState({[e.target.id]: e.target.value})
    }

    handlepickupdate = (event) =>{
        console.log(event.target.options[event.target.selectedIndex].text)
        if(event.target.value === '0'){
            this.setState({pickupdate: '',
            valuedate:''
            })
        }else{
            this.setState({pickupdate: event.target.value,
            valuedate: event.target.options[event.target.selectedIndex].text 
            })
        }       
    }

    render(){
        return(
            <div className="order-form">
            <form>
                <h2>Kirim Paket</h2>
                <p className="hint-text">Kami Akan Membantu Membuatkan Label Pengirimannya
                {/* <br/>(Anda Tidak Perlu Menuliskan Nama Pengirim Dan Penerima) */}
                <br/>Pengiriman Menggunakan SICEPAT</p>
                <div className="form-group">
                <h2>Data pengirim</h2>
                <div className="row">
                    <div className="col">Nama<input type="text" className="form-control" name="first_name" placeholder="Name" required="required" value={this.props.users.name} /></div>
                    <div className="col">No Handphone<input type="text" className="form-control" name="last_name" placeholder="phone" required="required" value={this.props.users.handphone} /></div> 
                </div>        	
                </div>
                <div className="form-group">

                <label for="inputName">Unit</label>
                    <input type="text" className="form-control" name="unit" placeholder="unit" required="required" value={this.props.users.address} />
                </div>
      

                <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="form-control input-group-text" >
                             Pickup
                        </div>
                        </div>
                        <select className="form-control custom-select" id="inputGroupSelect01" onChange={this.handlepickupdate}>
                            <option value="0">--- </option>
                            <option value="1" disabled={(this.currenttime < '08:00:00'? false: true)} >Hari Ini Pagi (09.00 ~ 10.00)</option>
                            <option value="2" disabled={(this.currenttime < '15:00:00'? false: true)} >Hari ini Sore (17.00 ~ 18.00)</option>
                            <option value="3">Besok Pagi (09.00 ~ 10.00)</option>
                            <option value="4">Besok Sore (17.00 ~ 18.00)</option>      
                        </select>
                </div>
               
                <h2>Data Penerima</h2>
                
                <div className="row">
                    <div className="col">Nama<input type="text" onChange={this.handlePenerimaChange} className="form-control" id="nama" placeholder="Name" required="required" /></div>
                    <div className="col">No Handphone<input type="number" min="0" onChange={this.handlePenerimaChange} className="form-control" id="phone" placeholder="phone" required="required" /></div>
                </div>        	

                
                <label for="inputName">Alamat</label>
                    <input type="text" onChange={this.handlePenerimaChange} className="form-control"  placeholder="alamat" id ="alamat" required="required" />
                

                
                <label for="inputName">Kecamatan</label>
                    <input type="text" onChange={this.handlePenerimaChange} className="form-control"  placeholder="kecamatan" id="kecamatan" required="required" />
               

                <label for="inputName">Kabupaten/Kota</label>
                    <input type="text" onChange={this.handlePenerimaChange} className="form-control"  placeholder="kabupaten" id="kabupaten" required="required" />
                
                <label for="inputName">Kode Pos</label>
                    <input type="number" min="0" onChange={this.handlePenerimaChange} className="form-control"  placeholder="kodepos" id="kodepos" required="required" />
                
                <label for="inputName">Isi Paket</label>
                    <input type="text" onChange={this.handlePenerimaChange} className="form-control" placeholder="isi" id="isi" required="required" />
                
                
                <label for="inputName">Jumlah barang dalam paket</label>
                    <input type="number" min="0" onChange={this.handlePenerimaChange} className="form-control" placeholder="jumlah" id="jumlah" required="required" />
               

                <label for="inputName">Estimasi harga barang dalam paket</label>
                    <input type="number"min="0" onChange={this.handlePenerimaChange} className="form-control" placeholder="harga" id="harga" required="required" />
                    
                <label for="inputName">Note :</label>
                    <input type="text" onChange={this.handleText} className="form-control"  id="comment" />
                   
            </form>
            <button onClick={this.checkPenerima} className="btn btn-success btn-lg btn-block">Pesan</button>
           

            </div>

        )
    }
}

export default withRouter(DeliveryPage);