import React, { Component } from 'react';
import {
    BrowserRouter ,
    Switch,
    Route
  } from "react-router-dom";
import MenuForm from '../component/menu';
import LaundryPage from './LaundryPage';
import LoginPage from './LoginPage';
import MainPage from './MainPage';
import GasMineralPage from './GasMineralPage';
import AircondPage from './AircondPage';
import PromoPage from './PromoPage';
import DeliveryPage from './DeliveryPage';
import AdminPage from './AdminPage';
import CatheringPage from './CatheringPage';
import AboutPage from './AboutPage';
import {Provider} from 'react-redux';
import {store} from '../config/redux'
import { createBrowserHistory } from 'history'
import {reactLocalStorage} from 'reactjs-localstorage';
import CleanPage from './CleanServicePage';
import CatheringAdminPage from './admin/catheringAdmin';
import RepairPage from './RepairPage';
import FoodHallPage from './FoodhallPage';
import RentCarPage from './RentCarPage';

import Cathering2Page from './Cathering2Page';
import MainPage2 from './MainPage2';
import Admin2Page from './Admin2Page';
import MservicePage from './MservicePage';
import AdminNewPage from './AdminNewPage';
import AdminVendorPage from './AdminVendorPage';
import UserAdmin from './admin/UserAdmin'



const history = createBrowserHistory()


class Routing extends Component{
    render(){
      const userSessionlocal = reactLocalStorage.getObject('USERS_CACHE');
        //console.log(userSessionlocal);
        return(
        <Provider store={store}>
        <BrowserRouter history={history}>
          <Switch>
            <Route path="/login">
              <MenuForm  />
              <LoginPage  />
            </Route>
            <Route path="/order/admin1">
              <AdminNewPage users={userSessionlocal}  />
            </Route>
            <Route path="/order/admin2">
              <Admin2Page users={userSessionlocal}  />
            </Route>
            <Route path="/order/admin/user">
              <UserAdmin users={userSessionlocal}  />
            </Route>
            <Route path="/order/admin">
              <AdminPage users={userSessionlocal}  />
            </Route>
            <Route path="/order/cathering/admin">
              <CatheringAdminPage users={userSessionlocal}  />
            </Route>
            <Route path="/orderLaundry">
              <MenuForm name={userSessionlocal.name} />
              <LaundryPage users={userSessionlocal} />
            </Route>
            <Route path="/orderDelivery">
              <MenuForm name={userSessionlocal.name} />
              <DeliveryPage users={userSessionlocal} />
            </Route>
            <Route path="/orderGasMineral">
              <MenuForm name={userSessionlocal.name} />
              <GasMineralPage users={userSessionlocal} />
            </Route>
            <Route path="/Foodhall">
              {/* <MenuForm name={userSessionlocal.name} /> */}
              <FoodHallPage users={userSessionlocal} />
            </Route>
            <Route path="/orderAircond">
              <MenuForm name={userSessionlocal.name} />
              <AircondPage users={userSessionlocal} />
            </Route>
            <Route path="/orderAircond">
              <MenuForm name={userSessionlocal.name} />
              <AircondPage users={userSessionlocal} />
            </Route>
            <Route path="/orderRepair">
              <MenuForm name={userSessionlocal.name} />
              <RepairPage users={userSessionlocal} />
            </Route>
            <Route path="/ordercleanser">
              <MenuForm name={userSessionlocal.name} />
              <CleanPage users={userSessionlocal} />
            </Route>
            <Route path="/orderRentcar">
              <MenuForm name={userSessionlocal.name} />
              <RentCarPage users={userSessionlocal} />
            </Route>
            <Route path="/orderCathering">
              <MenuForm name={userSessionlocal.name} />
              <Cathering2Page users={userSessionlocal} />
            </Route>
            <Route path="/orderMservices/:service">
              <MenuForm name={userSessionlocal.name} />
              <MservicePage users={userSessionlocal} />
            </Route>
          <Route path="/promo">
            <MenuForm name={userSessionlocal.name} />
            <PromoPage />
          </Route>
          <Route path="/about">
            <MenuForm name={userSessionlocal.name} />
            <AboutPage />
          </Route>
          <Route path="/orderan/vendor">
            <AdminVendorPage />
          </Route>
          {/* <Route path="/2">
            <MainPage users={userSessionlocal}/>
          </Route> */}
          <Route path="/">
            <MainPage2 users={userSessionlocal}/>
          </Route>
        </Switch>
        </BrowserRouter>
        
        </Provider>
        )
    }
}

export default Routing;