import React  from 'react';

const MenuBtn = (props) => {
        return(
            <div className="col-lg-6 mt-3">
            <a href={props.link}>
               <div className="member d-flex align-items-start">
                  <div><img src={props.btnImage} className="pic" alt={props.service} /></div>
                  <div className="member-info">
                  <h4>{props.service}</h4>
                  <span>{props.serviceDetail}</span>
                  </div>
               </div>
            </a>
            </div>
        )
}

export default MenuBtn;