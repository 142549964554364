import React, { Component } from 'react';
import axios from 'axios';

import '../component/modalForm.css';
// import '../component/css/adminlte.min.css';
import { withRouter } from 'react-router-dom';
import Cardfoodhall from '../component/atoms/card';
import '../component/css/foodhall.css';
import '../component/js/toggle.js'
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { Badge } from 'react-bootstrap';
import Logo from "../Logo.jpeg"; 


class FoodHallPage extends Component{
    state={
        showModal: false,
        listvendor:{},
        listfood:{},
        pickdate:'',
        delivdate:'',
        order:{},
        confirm:false,
        totalkeranjang:0,
        totalharga:0,
       comment : ''
   }
   
    

   componentDidMount(){
    // reactLocalStorage.setObject('ORDER_CACHE', {});

    if (this.props.users.name === '' || this.props.users.name === undefined){
      alert('Silahkan login sebelum melakukan transaksi');
      const {history} = this.props
      history.push({
          pathname: '/login'
      });   
  }
     
    axios.get('/foodhall/list/vendor')
    .then((vendorlist)=>{
      //console.log(vendor.data)
         this.setState({
              listvendor : vendorlist.data
          })        
    })

    axios.get('/foodhall/list/itemall')
    .then((foodlist)=>{
      //console.log('ini makanan',foodlist.data)
         this.setState({
              listfood : foodlist.data
          })        
    })

} 

    changeCategory = (category) =>{
      //  console.log('categorynya', category)
      axios.get('/foodhall/list/item',{params:{vendor_code:category}})
      .then((foodlist)=>{
        //console.log('ini makanan',foodlist.data)
           this.setState({
                listfood : foodlist.data
            })        
      })
    }


    // handleOpenModal =() =>{
    //     this.setState({preorder:0})
    //     Object.entries(this.state.order).map(([key,value])=>(
    //         (value>=1)&&this.setState({preorder:1})
    //     ))

    //     this.setState(
    //         { showModal: true }
    //         );
    //   }

    handleCloseModal =() => {
        // console.log('ini', this.state.showModal)
        this.setState({ showModal: false });
    }

    handlePlus= (e)=>{
        let plus = this.state.order[e.target.id]+1
        this.setState(prevState=>({
            order:{...prevState.order,[e.target.id]:plus}
        })
        )
    }

    handleMinus=(e)=>{
        let minus = this.state.order[e.target.id]-1
        if(this.state.order[e.target.id] > 0){
        this.setState(prevState=>({
            order:{...prevState.order,[e.target.id]:minus}
        })
        )}
        
        //console.log(this.state.order)
    }
    handleText=(event)=>{
        //console.log('ini',event)
        
            this.setState({[event.target.id]: event.target.value})
     
    }

    finishOrder=()=>{
        const {history} = this.props
        let pesanan = ''
        pesanan += '%0Anama%20%3A%20'+ this.props.users.name
        pesanan += '%0Aunit%20%3A%20'+ this.props.users.address
        pesanan += '%0Aitem order : %20%3A%20'
        Object.entries(this.state.order).map((key,value)=>{
            if(key[1] > 0){
                //console.log('ini key :', key)
                Object.entries(this.state.listfood)
                .filter(([kunci,isi]) => Number(isi.id) === Number(key[0]))
                .map((res)=>{
                //     console.log('ini res',res)
                     pesanan +=  '%0A%20%20%20'+res[1].cday + ', ' +res[1].ctime+'%20%3A%20'+key[1]
                }) 
            }
         })
         pesanan += '%0ANotes%20%3A%20'+this.state.comment

        window.open("https://wa.me/+6281281094975?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice."+
        pesanan+'%0Atolong%20segera%20di%20proses%20ya', '_blank', 'noopener,noreferrer')
        
        history.push({
            pathname: '/'
        });
    }

    submitOrder=()=>{
        let parameter ={
            userid : this.props.users.uid,
            order: this.state.order,
            comment: this.state.comment

        }
        
        axios.post('/cathering/addTrans',parameter)
        .then(()=>{
        this.modal =
        <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Pemesanan sukses</h4>
                    </div>
                    <div className='modal-body'>
                        <div>
                        <span>
                            <label>Pemesanan anda telah masuk ke dalam database kami dan akan kami proses. 
                            </label>
                            <br/>
                            <label>Klik tombol dibawah ini untuk konfirmasi WA </label>
                            </span>
                        </div>
                      
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.finishOrder} className="btn btn-primary btn-lg btn-xs">Konfirm Whatsapp</button>
                    {/* <a href={"https://wa.me/+6281281094975?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice.%0Anama%20%3A%20"+
                            this.props.users.name+"%0Atolong%20segera%20di%20proses%20ya.%20"} 
                            className="btn btn-primary btn-lg btn-xs">Konfirm whatsapp
                    </a> */}
                    </div>
                    </div>
                </div>
            </div>
            this.setState({confirm:true})
        })
        .catch((error)=>{
            console.log(error)
        })
        this.setState({showModal:false})
        console.log(this.modal)
        this.setState({showModal:true})

    }

    addtocart= async(value)=>{
    
    const res = Object.entries(this.state.order).filter(([key,isi])=> isi.item_code === value.item_code)
    .map((item,index)=>{
            return item[1].jumlah
        })
    //console.log('ini valuenya ',value)
    // res[0].map((item,index)=>{
    //     return console.log('ini ',item, ' dan ',index)
    // })

    const vendor = Object.entries(this.state.listvendor).filter(([key,isi])=> isi.vendor_code === value.vendor_code)
    .map((item,index)=>{
            return item[1].vendor_name
        })
    
    //console.log(' masuk', vendor)
    if(res.length ===0){
        
        const keranjang = {
            item_code : value.item_code,
            jumlah: 1,
            total_harga: value.price,
            vendor : vendor[0],
            product: value,
          };
          await this.setState((prevState)=>({
            order:{...prevState.order, [value.item_code] : keranjang}
          }))   

    }else{
        
        const keranjang = {
            item_code : value.item_code,
            jumlah: res[0] + 1,
            total_harga: value.price * (res[0] + 1),
            vendor : vendor[0],
            product: value,
          };
          await this.setState((prevState)=>({
            order:{...prevState.order, [value.item_code] : keranjang}
          }))   

        
        
    }
    // Object.entries(this.state.order).map((value,key) =>{
    //     this.setState({totalharga: this.state.totalharga+value[1].total_harga})
    //     console.log('get total : ', this.state.totalharga)
    //})
    

    this.setState({totalkeranjang: Object.keys(this.state.order).length})
    
    this.GetTotalHarga()
    console.log(this.state.totalkeranjang)
    console.log('hasilnya',this.state.order)

    }


    GetTotalHarga = async() =>{
        this.setState({totalharga: 5000})
       
        //const totalitemprice = 5000
        await Object.entries(this.state.order).map((value,key) =>{
              this.setState({totalharga: this.state.totalharga+value[1].total_harga})
            
        })
        console.log('get total : ', this.state.totalharga)
        //this.setState({totalharga: totalitemprice})
    }

    confirmpesanan = () => {
        let parameter ={
            userid : this.props.users.uid,
            order: this.state.order,
            comment: this.state.comment

        }
        console.log(this.state.order)
        
        if(this.state.totalharga >= 30000){

        Swal.fire({
            title: 'Pesanan anda telah sesuai?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'periksa kembali',
            confirmButtonText: 'Ya, pesan sekarang'
          }).then((result) => {

            if (result.isConfirmed) {
                
                axios.post('/foodhall/addTrans',parameter)
                .then(()=>{
                       Swal.fire(
                            'Terima Kasih',
                            'Tekan tombol "OK" untuk confirmasi pesanan via WA',
                            'success'
                        ).then(()=>{
                            let vendornya = ''
                            const {history} = this.props
                            let pesanan = ''
                            pesanan += '%0Anama%20%3A%20'+ this.props.users.name
                            pesanan += '%0Aunit%20%3A%20'+ this.props.users.address
                            pesanan += '%0APhone%20%3A%20' + this.props.users.handphone
                            pesanan += '%0Aitem order : %20%3A%20'
                            Object.entries(this.state.order).map((key,value)=>{
                                if (vendornya !== key[1].vendor) 
                                {
                                    console.log(vendornya,' ini vendornya',key[1].vendor)
                                    vendornya = key[1].vendor
                                    pesanan +="%0A=== "
                                    pesanan += key[1].vendor 
                                    pesanan += " ==="
                                }
                                //if(key[1].length > 0){
                                    //console.log('ini key :', key)
                                        //Object.entries(key[1].product)
                                        pesanan +=  '%0A%20%20%20'+key[1].product.item_name+'%20%3A%20'+key[1].jumlah+'%20%3A%20'+this.converttorupiah(key[1].total_harga)
                                //}
                            })
                            pesanan += '%0A_________________________________'
                            pesanan += '%0ATotal harga%20%20%20%20%20%20%20%20%20%3A%20'+ this.converttorupiah(this.state.totalharga - 5000)
                            pesanan += '%0AService fee%20%20%20%20%20%20%20%20%20%3A%20 Rp. 5.000'
                            pesanan += '%0ATotal pembayaran%20%3A%20'+ this.converttorupiah(this.state.totalharga )
                            

                            //pesanan += '%0ANotes%20%3A%20'+this.state.comment

                            window.open("https://wa.me/+6287723135869?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice."+
                            pesanan+'%0Atolong%20segera%20di%20proses%20ya', '_blank', 'noopener,noreferrer')

                            
                            history.push({
                                pathname: '/'
                            });
                        })
                         
                })
                .catch((error)=>{
                    console.log(error)
                })


            //   Swal.fire(
            //     'success',
            //     'Pesanan anda telah masuk kedalam system kami.',
            //     'success'
            //   )
            }
          })

        }else{
            Swal.fire(
                '',
                'Minimal pembelian untuk foodhall : Rp.25.000,- ',
                'error'
            )
        }
    }

    deleteorder = async(keyid) =>{
        
        const copyorder= {...this.state.order}
        await delete copyorder[keyid]
       await this.setState({
          order: copyorder,
       })

       await this.GetTotalHarga()
       this.setState({totalkeranjang: Object.keys(this.state.order).length})

        //var order = [...this.state.order]
        // delete this.state.order[keyid]
        //console.log(keyid,'isiorder : ',this.state.order)
        
      
    }

    converttorupiah = (value)=>{
        const format = value.toString().split('').reverse().join('');
        const convert = format.match(/\d{1,3}/g);
        const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
        return rupiah
    }


    render(){   


        if (this.state.showModal && this.state.preorder===0){
            //console.log(this.state.selectedSetrika)
            this.modal= <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                    </div>
                    <div className='modal-body'>
                        silahkan pilih paket makanan terlebih dahulu          
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        }
        else if(this.state.showModal && !this.state.confirm) { 
            this.modal =    <div className = 'modal'>
                    <div className='modal-dialog'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                            </div>
                            <div className='modal-body'>
                                <div>
                                <span>
                                    <label>nama </label> <label>: {this.props.users.name}</label>
                                    </span><br/>
                                    <span>
                                    <label>handphone </label> <label>: {this.props.users.handphone}</label>
                                    </span><br/>
                                    <span>
                                    <label>Unit </label> <label>: {this.props.users.address}</label>
                                    </span><br/>
                                </div>
                                <div>
                                    <h4>Pesanan </h4>
                                       
                                    {Object.entries(this.state.order).map((key,value)=>{
                                       if(key[1] > 0){
                                       return(
                                          // console.log(key[0],'-',key[1])
                                        <div>
                                            {Object.entries(this.state.listfood)
                                            .filter(([kunci,isi]) => Number(isi.id) === Number(key[0]))
                                            .map((res)=>{return(
                                                 <div>
                                                     {console.log('ini result',res)}
                                                     <div>{res[1].cday + ' ,' +res[1].ctime+' : '+key[1]}</div> 
                                                     <br />
                                                 </div>
    
                                             )})}
                                             
                                         </div>
                                       )
                                       
                                    }
                                    }
                                    )}
                                    <span>
                                        Note : {this.state.comment}
                                    </span>
                                </div>
    
                                
                            </div>
                            <div className='modal-footer'>
                            <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                            <button type='button' onClick={this.submitOrder} className="btn btn-primary btn-lg btn-xs">Confirm Pesanan</button>
                            </div>
                        </div>
                    </div>
                </div>
                }else if(this.state.showModal && this.state.confirm) {this.modal=this.modal }
    
                else{
                    this.modal =''
                }
    
        return(
          <div className="wrapper">
              <div className="d-flex" id="wrapper">
                {/* Sidebar*/}
                <div className="border-end bg-white" id="sidebar-wrapper">
                  <div className="sidebar-heading border-bottom bg-light">Tenant</div>
                  <div className="list-group list-group-flush">
                  {Object.entries(this.state.listvendor).map(
                      ([key,value])=>(
                        <button className="list-group-item list-group-item-action list-group-item-light p-3" onClick={this.changeCategory.bind(this,value.vendor_code)} >{value.vendor_name}</button>
          
                   ))}
                        
                  </div>
                </div>
                {/* Page content wrapper*/}
                <div id="page-content-wrapper">
                  {/* Top navigation*/}
                  <nav className="navbar fixed-top navbar-light bg-light ">
                    
                    <div className="container-fluid">
                      <button className="btn btn-secondary btn-sm  " id="sidebarToggle">Tenant</button>
                      <a href="index.html"><img src={Logo} height="40dp" /></a>
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="fa fa-shopping-cart" /><Badge pill bg="success">{this.state.totalkeranjang}</Badge></button>
                      <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        
                    <div className=" col-12 border-top border-bottom mt-2 ">
                    <div className="title">
                        <div className="row">
                        <div className="col mt-2">
                            <h4><b>Shopping Cart</b></h4>
                        </div>
                        <div className="col align-self-center text-right text-muted">{this.state.totalkeranjang} items</div>
                        </div>
                    </div>

                    {Object.entries(this.state.order).map((value,key) =>{
                        return(
                    // console.log('isi cart : ',value[1].product.picture)
                    <div className="row border-top border-bottom">  
                        <div className="row main align-items-center ">
                        {/* <div className="col-1"><img className="img-fluid" src={value[1].product.picture} /></div> */}
                        <div className="col-5">
                            {/* <div className="row text-muted">{value[1].product.item_name}</div> */}
                            <div className="row"><h6>{value[1].product.item_name}</h6></div>
                        </div>
                        <div className="col-2"> 
                            {/* <input type="number" id="replyNumber" min="0" data-bind={value[1].jumlah} />  */}
                            {value[1].jumlah} 
                        </div>
                        <div className="col-5"><NumberFormat value={value[1].total_harga} displayType={'text'} thousandSeparator={true} prefix={'Rp. '}/> <span className="close" onClick={this.deleteorder.bind(this,value[1].item_code)}><i class="fa fa-trash" aria-hidden="true" ></i></span></div>
                        </div>
                    </div>
                   
                    
                    )})}

                    <div className="row border-top border-bottom">  
                    <div className="row main align-items-center">
                        <div className="col-7"><h6>biaya admin</h6></div>
                        <div className="col-5"><NumberFormat value={5000} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /> </div>
                    </div>
                    <div className="row main align-items-center">
                        <div className="col-7"><h5>Total harga</h5></div>
                        <div className="col-5"><h5><NumberFormat value={this.state.totalharga} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /></h5> </div>
                        </div>
                    </div>


                    <div ><button className='btn btn-primary btn-sm mt-1 mb-1' onClick={this.confirmpesanan}>Pesan</button></div>
                    </div>


                      </div>
                    </div>
                  </nav>
                  {/* Page content*/}
                  <div className="container-fluid">
                        <section className="content">
                            <div className="container">
                            <div className="row ">
                            {Object.entries(this.state.listfood).map(
                              ([key, value]) => (
                              <Cardfoodhall
                               item={value}
                               masukankeranjang={this.addtocart} />
                            ))}

                                </div>
                            </div>
                        </section>
                  </div>
                </div>
              </div>  
</div>
        
        )
    }
}

export default withRouter(FoodHallPage);