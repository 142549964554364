import React, { Component, Fragment } from "react";

class PromoPage extends Component{
    render(){
        return(
            <div className="order-form">
            <form>
                <h2>Keep Calm</h2>
                <h2>and</h2>
                <h2>Wait Our Surprise</h2>  
            </form>   
            </div>
        )
    }
}

export default PromoPage;