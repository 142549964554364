import { reactLocalStorage } from "reactjs-localstorage";
import { withRouter } from 'react-router-dom';

const CheckLogin =()=>{
    const userCache=  reactLocalStorage.getObject('USERS_CACHE');

    if(userCache.name===""||userCache.name=== undefined ){
        const {history} = this.props
        alert('anda belum login, silahkan login terlebih dahulu')
        history.push({
            pathname: '/login'
        });    

    }

}

export const CheckLoginAgain =(props) =>{

    console.log('ini masuk')
  
     const {history} = props
      if (props.users.name === '' || props.users.name === undefined){
          alert('Silahkan login sebelum melakukan transaksi');
          history.push({
              pathname: '/login'
          });
          return('NG')
      }
  
  
      // const userCache=  reactLocalStorage.getObject('USERS_CACHE');
      // if(userCache.name===""||userCache.name=== undefined ){
      //     const {history} = this.props
      //     alert('anda belum login, silahkan login terlebih dahulu')
      //     history.push({
      //         pathname: '/login'
      //     });
      // }
  
  
  }
  
  export const CheckLoginContinue =(props) =>{
  
      //console.log('ini masuk')
    
       const {history} = props
        if (props.users.name === '' || props.users.name === undefined){
            alert('Silahkan login sebelum melakukan transaksi');
        }
    
    
   
    
    
    }

//export default withRouter(CheckLogin)