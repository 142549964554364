import firebase from '../../firebase'
import axios from 'axios';

export const actionUserName = () =>(dispatch)=>{
    setTimeout(() => {
        return dispatch({ type: 'CHANGE_USER', value: 'arif andriansyah'})
    }, 2000);
    
}

export const registerUserAPI = (data) => (dispatch)=> {
    return new Promise ((resolve,reject)=>{
        dispatch({type: 'CHANGE_LOADING', value: true})
        
        axios.post('/loginstate/',data)
        .then((userCredential)=>{
            console.log('ini user',userCredential)
            if(userCredential.data.message){
                dispatch({type: 'CHANGE_LOADING', value: false})
                dispatch({type: 'CHANGE_ISLOGIN', value: false})
                reject(false)
            }else{

            dispatch({type: 'CHANGE_LOADING', value: false})
            dispatch({type: 'CHANGE_ISLOGIN', value: true})
            dispatch({type: 'CHANGE_USER', value: userCredential.data})
            resolve(userCredential)
         }
        }).catch((error) => {
            var errorMessage = error.message;
            console.log(errorMessage)
            dispatch({type: 'CHANGE_LOADING', value: false})
            dispatch({type: 'CHANGE_ISLOGIN', value: false})
            reject(false)
          })
        
    // firebase.auth().createUserWithEmailAndPassword(data.email, data.password)
    //     .then((userCredential) => {
    //      console.log(userCredential)
    //      dispatch({type: 'CHANGE_LOADING', value: false})
    //     })
    //     .catch((error) => {
    //      dispatch({type: 'CHANGE_LOADING', value: false})
    //    })
    
})
}

export const loginUserAPI = (data) => (dispatch)=> {
    return new Promise ((resolve,reject)=>{
        dispatch({type: 'CHANGE_LOADING', value: true})
            axios.get('/loginstate/'+data.handphone+'/'+data.password
        //     ,{
        //         params:{
        //             handphone: data.handphone,
        //             password : data.password
        //         }
        // }
            )
            .then((userCredential) => {
                if(userCredential.data.message){
                    dispatch({type: 'CHANGE_LOADING', value: false})
                    dispatch({type: 'CHANGE_ISLOGIN', value: false})
                    reject(false)
                }else{

                dispatch({type: 'CHANGE_LOADING', value: false})
                dispatch({type: 'CHANGE_ISLOGIN', value: true})
                dispatch({type: 'CHANGE_USER', value: userCredential.data})
                resolve(userCredential)
             }
            })
            .catch((error) => {
              var errorMessage = error.message;
              console.log(errorMessage)
              dispatch({type: 'CHANGE_LOADING', value: false})
              dispatch({type: 'CHANGE_ISLOGIN', value: false})
              reject(false)
            })

    })
}