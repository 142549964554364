import React, { Component, Fragment } from 'react'; 
import MenuBtn from './mainButton';

 
 class  MainForm  extends Component {
    render(){
       
     return(
      <Fragment>
      <section id="team" className="team">
      <div className="container">
         <div className="section-title">
            <span>Services</span>
            <h2>Choose service</h2>
         </div>
         <div className="row">
            <MenuBtn 
               service="Laundry"
               serviceDetail='Best option for your clothes' 
               btnImage="/dist/img/laundry.png"
               link="/orderLaundry"/>
           
            <MenuBtn 
               service="Gallon and Gas"
               serviceDetail='Easy order for your gallon and gas' 
               btnImage="/dist/img/gallon.png"
               link="/orderGasMineral"/>

            <MenuBtn 
               service="FoodHall"
               serviceDetail='Foodhall near you' 
               btnImage="/dist/img/foodhall.png"
               link="/foodhall"
              />

            <MenuBtn 
               service="AC Service"
               serviceDetail='Make sure your AC in good condition' 
               btnImage="/dist/img/aircond.png"
               link="/orderAircond"/>
            
            <MenuBtn 
               service="Cleaning Service"
               serviceDetail='Clean up your room' 
               btnImage="/dist/img/cleaning.png"
               link="/ordercleanser"/>


            <MenuBtn 
               service="Repair Unit"
               serviceDetail='Repair and maintenance Unit' 
               btnImage="/dist/img/repair.png"
               link="/orderRepair"
               />

            <MenuBtn 
               service="Car Rent "
               serviceDetail='rent a car for every moment' 
               btnImage="/dist/img/carwash.png"
               link="/orderRentCar"
               />  

            <MenuBtn 
               service="Cathering"
               serviceDetail='Delicious and healthy food for you' 
               btnImage="/dist/img/cathering.png"
               link="/orderCathering"
                />

            <MenuBtn 
               service="Beauty Care"
               serviceDetail='Freedom of your beauty' 
               btnImage="/dist/img/makeover.png"
               link="/orderMservices/BeautyCare"
                />

            <MenuBtn 
               service="Delivery "
               serviceDetail='Save your time to send parcel' 
               btnImage="/dist/img/shipping.png"
               link='/orderDelivery'
                />
            

            <MenuBtn 
               service="Design Interior (Coming soon)"
               serviceDetail='' 
               btnImage="/dist/img/interior.png"
             />
          
              
           
            <MenuBtn 
               service="Health Care (Coming soon)"
               serviceDetail='Always keep best condition of your health ' 
               btnImage="/dist/img/doctor.png"
                />

         </div>
      </div>
      </section>

      </Fragment>
     )
    }
    
 }

 export default MainForm;