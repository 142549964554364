import React, { Component, Fragment } from 'react';
import axios from 'axios';
import './order.css';
import '../component/modalForm.css';
import MenuAdminForm from '../component/menuadmin';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import date from 'date-and-time';
import AdminMenu from './admin/adminMenu';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons'



class Admin2Page extends Component{
     state={
         showModal: false,
        listtrans:{},
        delivdate:'',
        order:[],
        confirm:false,
        fromdate:'',
        todate: '',
        typetrans:'',
        vendor:''
    } 
   modal='ok'

   loginForm=()=>{
        Swal.fire({
            title: 'Login Form',
            html: `<input type="text" id="login" class="swal2-input" placeholder="Username">
            <input type="password" id="password" class="swal2-input" placeholder="Password">`,
            confirmButtonText: 'Sign in',
            focusConfirm: false,
            preConfirm: () => {
            const login = Swal.getPopup().querySelector('#login').value
            const password = Swal.getPopup().querySelector('#password').value
            if (!login || !password) {
                Swal.showValidationMessage(`Please enter login and password`)
            }
            return { login: login, password: password }
            }
        }).then((result) => {
            axios.post('/order/admin/cekadmin',{params:result})
            .then((login)=>{
                console.log(login.data)
                if(login.data==='4dm1n'){
                    Swal.fire({
                        title: 'Loading data ',
                        html: 'please wait ..',
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            let parameter = {fromdate : new Date(), todate: new Date(), vendor:'all'}
                            //console.log(parameter)
                            axios.get('/order/admin/transaction2',{params:parameter})
                            .then((trans)=>{
                                console.log('ini adalah',trans)
                                this.setState({
                                    listtrans: trans.data
                                })  
                                Swal.close()
                            })
                          
                        }})
                   

                }else{
                    Swal.fire({
                        title:'error',
                        text:'username atau password salah',
                        icon:'error'
                        
                    }).then(()=>{
                        Swal.close()
                        this.loginForm()
                    })
                    
                }   
                
            })
        
      })

   }

    componentDidMount(){

        this.loginForm()
        
        


         
        // axios.get('/order/admin/alltransaction2')
        // .then((trans)=>{
        //     //console.log('ini adalah',trans)
        //      this.setState({
        //          listtrans: trans.data
        //      })  
        // })

        
    } 

    handleOpenModal =() =>{
        this.setState(
            { showModal: true }
            );
      }

      handleCloseModal =() => {
          console.log('ini', this.state.showModal)
        this.setState({ showModal: false });
      }

      handleDelivDate = (e) =>{
          this.setState({
              delivdate: e.target.value
          })//,()=>console.log(this.state.delivdate))
          
          }

    handleChangeText = (e) =>{
            this.setState({
                [e.target.id]:e.target.value
            })
        //console.log(this.state.fromdate,'----',this.state.typetrans)
        }
   

    HandleFilter=()=>{
        if(this.state.fromdate <= this.state.todate) { 
            let parameter = {fromdate : this.state.fromdate, todate: this.state.todate,vendor:'all'}
            Swal.fire({
                title: 'Loading data ',
                html: 'please wait ..',
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    console.log(parameter)
                    axios.get('/order/admin/transaction2',{params:parameter})
                    .then((trans)=>{
                        //  console.log('ini adalah',trans)
                        this.setState({
                            listtrans: trans.data
                        })  
                        Swal.close()
                    })
                  
                }})
            // console.log('params ', parameter)
            // axios.get('/order/admin/transaction2', {params:parameter})
            //     .then((trans)=>{
            //     console.log('ini adalah',trans)
            //      this.setState({
            //          listtrans: trans.data
            //      })  
            // })
        }else{ console.log('salah')}

    }

    getzonedate=(tanggal)=>{
        if (tanggal === null){
            return null
        } else{
        let newdate = new Date(tanggal);
        newdate.setHours(newdate.getHours())
        //console.log(newdate)
        return date.format(newdate,'YYYY-MM-DD HH:mm:ss')
    }
    }

    delivpickdate=(tanggal)=>{
        if (tanggal === null){
            return null
        } else{
        let newdate = new Date(tanggal);
        //console.log( 'datanya',newdate)
        return  newdate.toISOString().replace('Z', ' ').replace('T', ' ').replace('.000','')
        }
    }

    setDateRange=(update)=>{
        this.setState({fromdate:update[0]})
        this.setState({todate:update[1]})
    }

    editData = async(val) =>{
        console.log(val)
        const { value: formValues } = await Swal.fire({
            title: 'update transaction',
            showCancelButton: true,
            html:
            '<div><label>transid :</label><input id="swal-input1" class="swal2-input" readonly value="'+val.transid+'"><div>' +
            '<div><label>name :</label><input id="swal-input2" class="swal2-input" readonly value="'+val.name+'"><div>' +
            '<div><label>unit :</label><input id="swal-input3" class="swal2-input" readonly value="'+val.unit+'"><div>' +
            '<div><label>category :</label><input id="swal-input4" class="swal2-input" readonly value="'+val.category+'"><div>' +
            '<div><label>item :</label><input id="swal-input5" class="swal2-input" readonly value="'+val.item+'"><div>' +
            '<div><label>quantity* :</label><input id="swal-input6" class="swal2-input" value="'+val.quantity+'"><div>' +
            '<div><label>notes* :</label><input id="swal-input7" class="swal2-input" value="'+val.notes+'"><div>', 
            //'<input id="swal-input2" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
                let parameter = {
                    transid:document.getElementById('swal-input1').value,
                    name:document.getElementById('swal-input2').value,
                    unit: document.getElementById('swal-input3').value,
                    category:  document.getElementById('swal-input4').value,
                    item:  document.getElementById('swal-input5').value,
                    quantity:  document.getElementById('swal-input6').value,
                    notes:  document.getElementById('swal-input7').value,
                }

                axios.post('/order/admin/updatetrans',{params:parameter})
                    // .then((result)=>{
                    //     axios.post('/order/admin/usergetmanage',{xnpm:"p02p02p02"})
                    //     .then((user)=>{
                    //         //console.log('ini adalah',user)
                    //         this.setState({
                    //             listuser: user.data
                    //         })
                    //     }  
                    //     )
                        
                    // }
                    
                    // )
                
                
                
               

            
            }
          })
          
        //   if (formValues) {
        //     Swal.fire(JSON.stringify(formValues))
        //   }
    }
   

    deleteData = async (val) =>{
        Swal.fire({
            title: 'Kamu yakin?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus '+val.transid+'!'
          }).then((result) => {
            if (result.isConfirmed) {
                //param = val
               // await axios
              Swal.fire(
                'Deleted!',
                '',
                'success'
              )
            }
          })
    }

    
    
    render(){   

        return(
            <div className="d-flex justify-content-center">
           
            {/* <MenuAdminForm/>
            <div className="input-daterange input-group" id="datepicker">
            <input type="text" className="input-sm form-control" name="start" />
            </div> */}

            
                {/* <section className="content"> */}
                <div className="row" >
                    <div className="col-12">
                    <div>
                       <AdminMenu></AdminMenu>

                       <div className="row m-2 justify-content-md-end">
        
                            <div className="col col-md-auto">
                                <label>Date range:</label>  
                            </div>
                                {/* <div className='col-4'> <DatePicker selected={this.state.fromdate}  onChange={(date) => this.setState({fromdate:date})} /> </div> 
                                <div className='col-1'> to </div>
                                <div className='col-4'><DatePicker selected={this.state.todate}  onChange={(date) => this.setState({todate:date})} /> </div> */}
                            <div className="col col-md-auto">       
                                <DatePicker
                                    todayButton="Today"
                                    selectsRange={true}
                                    startDate={this.state.fromdate}
                                    endDate={this.state.todate}
                                    onChange={(update) => {
                                        this.setDateRange(update);
                                    }}
                                    />
                            </div>  
                            <div className="col col-md-auto "> 
                                <button type="submit" className="btn btn-success btn-block btn-sm" onClick={this.HandleFilter} >filter</button>
                            </div>

                        </div>`
                    <div>
                       
                        <Table striped bordered hover size="sm"  >
                            <thead>
                            <tr>
                                <th>trans_id</th>
                                <th>transdate</th>
                                <th>name</th>
                                <th>handphone</th>
                                <th>unit</th>
                                <th>category</th>
                                <th>pickupdate</th>
                                <th>deliverydate</th>
                                <th>item</th>
                                <th>quantity</th>
                                <th>price</th>
                                <th>notes</th>
                                <th>baseprice</th>
                                
                                 <th>action</th> 
                                
                                </tr>
                            </thead>
                             {console.log('trans',this.state.listtrans)} 
                            <tbody className='justify-content-center'>
                            {Object.entries(this.state.listtrans)
                            .map(([key,value])=>(
                                    <tr>
                                        {this.tanggal = value.transdate}
                                        <td><small>{value.transid.slice(-5)}</small></td>
                                        <td><small>{this.delivpickdate(value.transDate)}</small></td>
                                        <td><small>{value.name}</small></td>
                                        <td><small>{value.handphone}</small></td>
                                        <td><small>{value.unit}</small></td>
                                        <td><small>{value.category}</small></td>
                                        <td><small>{this.delivpickdate(value.pickupDate)}</small></td>
                                        <td><small>{this.delivpickdate(value.deliveryDate)}</small></td>
                                        <td><small>{value.item}</small></td>
                                        <td><small>{value.quantity}</small></td>
                                        <td><small>{value.itemprice}</small></td>
                                        <td><small>{value.notes}</small></td> 
                                        <td><small>{value.baseprice}</small></td>
                                        <td>
                                            <div className='d-flex'>
                                            <div className='p-1' onClick={this.editData.bind(this,value)}><FontAwesomeIcon icon={faEdit} /></div> <div className='p-1' onClick={this.deleteData.bind(this,value)}><FontAwesomeIcon icon={faTrashCan} /></div>
                                            </div>
                                        </td>
                                    </tr>
                                        
      
                                )
                        )}
                            
                            </tbody>
                                
                        </Table>
                        </div>
                    </div>
                    </div>
                </div>
                    {/* </section> */}

                  
            </div>
        )
    }
}

export default Admin2Page;