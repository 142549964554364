import React, { Component } from 'react';
import axios from 'axios';
import '../order.css';
import '../../component/modalForm.css';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

class CatheringAdminPage extends Component{
    state={
        showModal: false,
        listfood:{},
        pickdate:'',
        delivdate:'',
        order:{},
        confirm:false,
        preorder:0,
        hari:['senin','selasa','rabu','kamis','jumat','sabtu','minggu'],
        comment : '',
        respass:''
   }
    

    componentDidMount(){
    axios.get('/cathering/list')
        .then((foodlist)=>{
            this.setState({
                listfood: foodlist.data
            })        
        console.log(this.state.listfood)
        })
    } 


    handleOpenModal =() =>{
        this.setState({preorder:0})
        Object.entries(this.state.order).map(([key,value])=>(
            (value>=1)&&this.setState({preorder:1})
        ))

        this.setState(
            { showModal: true }
            );
      }

    handleCloseModal =() => {
        // console.log('ini', this.state.showModal)
        this.setState({ showModal: false });
    }

    handleText=(event)=>{
        //console.log('ini',event)
        this.setState(prevState => ({
            listfood: {
              ...prevState.listfood,           // copy all other key-value pairs of food object
              [event.target.id]: {                     // specific object of food object
                ...prevState.listfood[event.target.id],   // copy all pizza key-value pairs
                cname: event.target.value          // update value of specific key
              }
            }
          }))
        
          console.log(this.state.listfood)
            //this.setState({[event.target.id]: event.target.value})
     
    }


    confirmupdate = () =>{
        let res = prompt("Pastikan data telah benar. Masukan password lalu tekan OK");
        
        if(res !== ''){
            let parameter ={
                         listfood : this.state.listfood,
                         pass: res
                  }
            axios.post('/cathering/admin/update',parameter)
            .then(()=>{
                alert('data berhasil di update')
            })
            .catch((error)=>{
                         alert('pastikan password yang anda masukan benar')
                     })


        }
    }

    // submitOrder=()=>{
    //     let parameter ={
    //         userid : this.props.users.uid,
    //         order: this.state.order,
    //         comment: this.state.comment

    //     }
        
    //     axios.post('/cathering/addTrans',parameter)
    //     .then(()=>{
    //     this.modal =
    //     <div className = 'modal'>
    //         <div className='modal-dialog'>
    //             <div className='modal-content'>
    //                 <div className='modal-header'>
    //                     <h4 className='modal-title'>Pemesanan sukses</h4>
    //                 </div>
    //                 <div className='modal-body'>
    //                     <div>
    //                     <span>
    //                         <label>Pemesanan anda telah masuk ke dalam database kami dan akan kami proses. 
    //                         </label>
    //                         <br/>
    //                         <label>Klik tombol dibawah ini untuk konfirmasi WA </label>
    //                         </span>
    //                     </div>
                      
    //                 </div>
    //                 <div className='modal-footer'>
    //                 <button type='button' onClick={this.finishOrder} className="btn btn-primary btn-lg btn-xs">Konfirm Whatsapp</button>
    //                 {/* <a href={"https://wa.me/+6281281094975?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice.%0Anama%20%3A%20"+
    //                         this.props.users.name+"%0Atolong%20segera%20di%20proses%20ya.%20"} 
    //                         className="btn btn-primary btn-lg btn-xs">Konfirm whatsapp
    //                 </a> */}
    //                 </div>
    //                 </div>
    //             </div>
    //         </div>
    //         this.setState({confirm:true})
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //     })
    //     this.setState({showModal:false})
    //     console.log(this.modal)
    //     this.setState({showModal:true})

    // }

    render(){

        return(
            <div className="order-form">
            <form >
                <h2>Catering Setting</h2>

                <div className="form-group">
                {console.log(this.state.listfood)}
                         <div className=''>
                         {Object.entries(this.state.listfood)
                         .map(([keys,value])=>
                               <div className="col-md-12 ">
                                   {console.log(value,'ini ',this.state.listfood.cname)}
                                    
                                            <div>{value.cday+' - '+value.ctime}</div>
                                            <input className="form-control" id={keys} type="text" onChange={this.handleText} value={this.state.listfood[keys].cname} />
                                        </div>           
                         )}
                        </div>  
                    </div>

                </form>  
                

                <div className="form-group">
                <button type="submit" className="btn btn-success btn-lg btn-block" onClick={this.confirmupdate} >Update</button>
                </div>

                {this.modal}
                
            </div>

        )
    }
}

export default withRouter(CatheringAdminPage);