import React  from 'react';
import { Col } from "react-bootstrap";

const MenuBtn = (props) => {
        return(
           
            <Col lg={2} md={3} xs={3} className="text-center mb-3" > 
               {/* <button type="button" className="btn p-2 shadow-lg shadow-success p-3 mb-1 bg-info rounded rounded-circle btn-lg"> */}
               <a href={props.link} role="button"  padding={'120px'} height={'60px'} className="btn btn-outline-info shadow shadow-success p-2 mb-1 rounded rounded-3 btn-lg" >
                  <img   z-index={1} className='position-static' src={props.btnImage} width={'60px'} height={'60px'}></img> 

                  {/* <img  z-index={2}  className='position-relative start-10' src={"/dist/img/laundry.png"} width={'60px'} height={'60px'}></img>  */}
               </a>
               <h6 className="text-center">{props.service}</h6>
            {/* <a href={props.link}>
               <div className="member d-flex align-items-start">
                  <div><img src={props.btnImage} className="pic" alt={props.service} /></div>
                  <div className="member-info">
                  <h4>{props.service}</h4>
                  <span>{props.serviceDetail}</span>
                  </div>
               </div>
            </a> */}
            </Col>
        )
}

export default MenuBtn;