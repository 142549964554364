import React, { Component } from 'react';
import axios from 'axios';
import './order.css';
import '../component/modalForm.css';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';
import date from 'date-and-time'
import {CheckLoginAgain, CheckLoginContinue} from '../component/atoms/function'
import maintenance from '../component/atoms/maintenance';

class LaundryPage extends Component{
    state={
        showModal: false,
       listnonkilo:{},
       listpaket:{},
       pickdate:'',
       delivdate:'',
       pickvalue:'',
       delivvalue:'',
       order:{},
       confirm:false,
       selectedcuci:{},
        selectedSetrika:{},
        preorder:0,
        comment:''
   }

   currenttime = date.format(new Date(),'HH:mm:ss')


    componentDidMount(){

        // maintenance(this.props,'LAUNDRY','30 April 2022','6 Mei 2022')
        
        // if (this.props.users.name === '' || this.props.users.name === undefined){
        //     alert('Silahkan login sebelum melakukan transaksi');
        //     const {history} = this.props
        //     history.push({
        //         pathname: '/login'
        //     });   
        // }

        CheckLoginContinue(this.props)

        this.setState({
            selectedcuci: '',
            selectedSetrika: ''
        })
        if (this.currenttime > '00:30:00' ){
        console.log(true)
        }else{
            console.log(false)
        }
        

        axios.get('/laundry/nonkilolist')
        .then((nonkilo)=>{
            this.setState({
                listnonkilo: nonkilo.data
            })
           // console.log(this.state.listnonkilo);
            Object.entries(this.state.listnonkilo).map(
                ([key,value])=>(
                    //console.log('ini valuenya ',value)
                    this.setState(prevState =>({
                        order:{
                            ...prevState.order, [value.nonKiloID] :0}
                    }))
                )
            )        
        })
        
        axios.get('/laundry/packagelist')
        .then((paket)=>{
            //console.log(paket)
            this.setState({
                listpaket : paket.data
            })
            //console.log(this.state.listpaket)
        })
        
    } 

    handleOpenModal =async () =>{
        await   CheckLoginAgain(this.props)
        console.log(this.state.order)
        this.setState({preorder:0})
        Object.entries(this.state.order).map(([key,value])=>(
            (value>=1)&&this.setState({preorder:1})
        ))

        this.setState(
            { showModal: true }
            );
      }
    
    handleCloseModal =() => {
     //   console.log('ini', this.state.showModal)
      this.setState({ showModal: false });
    }


    handleTBChange=(event)=>{
        //console.log(' ini eventnya :', event.target.id )
        let x=event.target.id
        if(event.target.checked){
            
            this.setState(prevState=>({
                order:{...prevState.order,[x]:1}
            })
            )
        }else{
            this.setState(prevState=>({
                order:{...prevState.order,[x]:0}
            }))
        }
        //console.log(' trial ', this.state.order)
    }

    handlepackage=(event)=>{
        //console.log('yang ini kan?',event.target.value)
        //console.log(this.state.listpaket)
        //let harga = Object.entries(this.state.listpaket).filter((value)=>value.id=event.target.value)
        if(event.target.value === '0' ){
            //console.log('try dlu')
            if(event.target.id === "cuci"){
                this.setState({
                    selectedcuci: ''
                })
            //console.log('ini masuk yaa')
            }else {
                this.setState({
                    selectedSetrika: ''
                })
            //    console.log('ini masuk yaa ok')
            }
        }else{
            Object.entries(this.state.listpaket)
            .filter(([key,value])=> value.id === Number(event.target.value))
            .map(([key,value])=>{
                if(value.servicename ==='Cucisetrika'){
                    this.setState({
                        selectedcuci: value
                    })
                    //console.log('masuk cuci:', this.state.selectedcuci)
                }else{
                    this.setState({
                        selectedSetrika: value
                    })
                    //console.log('masuk setrika:', this.state.selectedSetrika)
                }    
            })
        }
    }

    handleDate=(event)=>{
        console.log('ini',event.target.value)
        if(event.target.value === '0'){
            this.setState({[event.target.id]: ''})
            if(event.target.id === 'pickdate'){
                this.setState({pickvalue:''})
            }else{
                this.setState({delivvalue:''})
            }
        }else{
            //console.log('handle:',event.target.)
            this.setState({[event.target.id]: event.target.value})
            if(event.target.id === 'pickdate'){
                this.setState({pickvalue:event.target.options[event.target.selectedIndex].text})
            }else if(event.target.id === 'delivdate'){
                this.setState({delivvalue:event.target.options[event.target.selectedIndex].text})
            }
        }
     console.log(this.state)
    }

    finishOrder=()=>{
        //console.log('ini setrika :',this.state.selectedSetrika)
        const {history} = this.props
        let pesanan = ''
        pesanan += '%0Anama%20%3A%20'+ this.props.users.name
        pesanan += '%0Aunit%20%3A%20'+ this.props.users.address
        pesanan += '%0APhone%20%3A%20' + this.props.users.handphone
        pesanan += '%0Aitem order : %20%3A%20'
        if(this.state.selectedcuci !== '' ){
            pesanan += '%0A%20%20%20'+this.state.selectedcuci.servicename+' : '+this.state.selectedcuci.packagename
        }
    
        if(this.state.selectedSetrika != '' ){
            //console.log('ini masuk setrika')
            pesanan += '%0A%20%20%20'+this.state.selectedSetrika.servicename+' : '+this.state.selectedSetrika.packagename
        }

        Object.entries(this.state.order).map((key,value)=>{
            if(key[1] > 0){
                //console.log('ini key :', key)
                Object.entries(this.state.listnonkilo)
                .filter(([kunci,isi]) => isi.nonKiloID === Number(key[0]))
                .map((res)=>{
                //     console.log('ini res',res)
                     pesanan +=  '%0A%20%20%20'+res[1].item
                }) 
            }
         })
         pesanan += '%0A%0AWaktu Pickup%20%3A%20'+this.state.pickvalue
         pesanan += '%0AWaktu Antar%20%3A%20'+this.state.delivvalue
         pesanan += '%0ANotes%20%3A%20'+this.state.comment

        window.open("https://wa.me/+6281311699098?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice."+
        pesanan+'%0Atolong%20segera%20di%20proses%20ya', '_blank', 'noopener,noreferrer')

        history.push({
            pathname: '/'
        });
    }

    submitOrder=()=>{
        let parameter ={
            userid : this.props.users.uid,
            cuci : this.state.selectedcuci,
            setrika : this.state.selectedSetrika,
            pickdate: this.state.pickdate,
            delivdate: this.state.delivdate,
            order: this.state.order,
            comment: this.state.comment
        }
        
        axios.post('/laundry/addTrans',parameter)
        .then(()=>{
        this.modal =
        <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Pemesanan sukses</h4>
                    </div>
                    <div className='modal-body'>
                        <div>
                        <span>
                            <label>Pemesanan anda telah masuk ke dalam database kami dan akan kami proses. 
                            </label>
                            <br/>
                            <label>Klik tombol dibawah ini untuk konfirmasi WA </label>
                            </span>
                        </div>
                      
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.finishOrder} className="btn btn-primary btn-lg btn-xs">Konfirm Whatsapp</button>
                    {/* <a href={"https://wa.me/+6281281094975?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice.%0Anama%20%3A%20"+
                            this.props.users.name+"%0Atolong%20segera%20di%20proses%20ya.%20"} 
                            className="btn btn-primary btn-lg btn-xs">Konfirm whatsapp
                    </a> */}
                    </div>
                    </div>
                </div>
            </div>
            this.setState({confirm:true})
        })
        .catch((error)=>{
            console.log(error)
        })
        this.setState({showModal:false})
        console.log(this.modal)
        this.setState({showModal:true})

    }
    
    render(){
        if (this.state.showModal && (Object.keys(this.state.selectedSetrika).length===0 && Object.keys(this.state.selectedcuci).length === 0 && this.state.preorder === 0)){
            //console.log(this.state.selectedSetrika)
            this.modal= <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                    </div>
                    <div className='modal-body'>
                        silahkan pilih paket cuci , setrika atau paket non-kilo terlebih dahulu           
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        }
        else if(this.state.showModal && (this.state.pickdate==='' || this.state.delivdate==='')){
            this.modal= <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                    </div>
                    <div className='modal-body'>
                        silahkan pilih waktu pickup dan waktu antar        
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        }
        else if(this.state.showModal && !this.state.confirm) { 
            this.modal =    <div className = 'modal'>
                    <div className='modal-dialog'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                            </div>
                            <div className='modal-body'>
                                <div>
                                <span>
                                    <label>nama </label> <label>: {this.props.users.name}</label>
                                    </span><br/>
                                    <span>
                                    <label>handphone </label> <label>: {this.props.users.handphone}</label>
                                    </span><br/>
                                    <span>
                                    <label>Unit </label> <label>: {this.props.users.address}</label>
                                    </span><br/>
                                </div>
                                <div>
                                    <h4>Pesanan kilo</h4>
                                        {/* {console.log(this.state.selectedcuci.servicename)} */}
                                        <div>{this.state.selectedcuci.servicename} = {this.state.selectedcuci.packagename}</div>
                                        <br />
                                        <div>{this.state.selectedSetrika.servicename} = {this.state.selectedSetrika.packagename}</div>
                                        
                                    <p />
                                    <h4>Pesanan Non-kilo</h4>
                                    {Object.entries(this.state.order).map((key,value)=>{
                                       if(key[1] > 0){
                                       return(
                                           //console.log(key[0],'-',key[1])
                                       <div>
                                            
                                           
                                             {Object.entries(this.state.listnonkilo)
                                            .filter(([kunci,isi]) => isi.nonKiloID === Number(key[0]))
                                            .map((res)=>{return(
                                                 <div>
                                                     {console.log('ini result',res)}
                                                     <div>{res[1].item}</div> 
                                                     <br />
                                                 </div>
    
                                             )})}
                                         </div>
                                       )
                                       
                                    }
                                    }
                                    )}
                                    <span>
                                    </span>
                                </div>
    
                                
                            </div>
                            <div className='modal-footer'>
                            <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                            <button type='button' onClick={this.submitOrder} className="btn btn-primary btn-lg btn-xs">Confirm Pesanan</button>
                            </div>
                        </div>
                    </div>
                </div>
                }else if(this.state.showModal && this.state.confirm) {this.modal=this.modal }
    
                else{
                    this.modal =''
                }
    

        return(
            <div className="order-form">
            <form action="/examples/actions/confirmation.php" method="post">
                <h2>Laundry Order</h2>
                <p className="hint-text">Bersihkan pakaianmu setiap saat</p>
                <div className="form-group">
                <div className="row">
                    <div className="col">Nama<input type="text" className="form-control" name="first_name" placeholder="Name" required="required" value={this.props.users.name} /></div>
                    <div className="col">No Handphone<input type="text" className="form-control" name="last_name" placeholder="phone" required="required" value={this.props.users.handphone} /></div>
                </div>        	
                </div>
                <div className="form-group">

                <label htmlFor="inputName">Unit</label>
                    <input type="text" className="form-control" name="unit" placeholder="unit" required="required" value={this.props.users.address} />
                </div>
                <h2>Kiloan</h2>
      

                <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="form-control input-group-text" >
                             Pickup
                        </div>
                        </div>
                        <select className="form-control custom-select" id="pickdate" onChange={this.handleDate} >
                           <option value='0'> ------ </option>
                            <option value="1" disabled={(this.currenttime < '09:00:00'? false: true)}>HARI INI PAGI (10.00 ~ 11.00)</option>
                            <option value="2" disabled={(this.currenttime < '16:00:00'? false: true)}>HARI INI SORE (17.00 ~ 18.00)</option>
                            <option value="3">BESOK PAGI (10.00 ~ 11.00)</option>
                            <option value="4">BESOK SORE (17.00 ~ 18.00)</option>
                        </select>
                </div>
                
                <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="form-control input-group-text" >
                              Antar
                        </div>
                        </div>
                        <select className="form-control custom-select" id="delivdate" onChange={this.handleDate}>
                            <option value='0'> ------ </option>
                            <option value="1">PAGI (10.00 ~ 11.00)</option>
                            <option value="2">SORE (17.00 ~ 18.00)</option>
                        </select>
                </div>

                <div className=" form-group input-group with-harga">
                    <div className="input-group-prepend">
                        
                        <div className="form-control input-group-text" >
                             cuci setrika
                        </div>
                        </div>
                        <select className="form-control custom-select" id="cuci" onChange={this.handlepackage} >
                        <option value='0'> ------ </option>
                        {Object.entries(this.state.listpaket)
                        .filter(([key,value])=> value.servicename === 'Cucisetrika')
                        .map(([key,value])=>(
                            //console.log(key,value)
                            <option value={value.id}> {value.packagename} </option>
                        ))}

                        </select>
                    <div>
                    <label className="labelharga"> {this.state.selectedcuci.packagename}:  Rp. {this.state.selectedcuci.second_price}/Kg (Minimal 4Kg ) </label>
                    {/* <label className="labelharga"> {this.state.selectedcuci.packagename}:  Rp {this.state.selectedcuci.first_price} (4 Kg Pertama) & Rp. {this.state.selectedcuci.second_price}/Kg </label> */}
                    </div>
                </div>


                <div className=" form-group input-group with-harga">
                    <div className="input-group-prepend">
                        
                        <div className="form-control input-group-text" >
                              Setrika saja
                        </div>
                        </div>
                        <select className="form-control custom-select" id="setrika"  onChange={this.handlepackage}>
                        <option value='0'> ------ </option>
                        {Object.entries(this.state.listpaket)
                        .filter(([key,value])=> value.servicename === 'setrika')
                        .map(([key,value])=>(
                            //console.log(key,value)
                            <option value={value.id}> {value.packagename} </option>
                        ))}
                        </select>
                    <div>
                    <label className="labelharga"> {this.state.selectedSetrika.packagename}: Rp. {this.state.selectedSetrika.second_price}/Kg (Minimal 4Kg )</label>
                    {/* <label className="labelharga"> {this.state.selectedSetrika.packagename}:  Rp {this.state.selectedSetrika.first_price} (4 Kg Pertama) & Rp. {this.state.selectedSetrika.second_price}/Kg Berikutnya</label> */}
                    </div>
                </div>


                <h2>Non Kiloan</h2>

                
                {Object.entries(this.state.listnonkilo).map(
                    ([key,value])=>(
                        //console.log(value)
                    <div className="form-group">
                        <label className="form-check-label">
                            <input type="checkbox" onChange={this.handleTBChange} required="required" id={value.nonKiloID} />{value.item} : 
                            <NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /> 
                        </label>
                        </div>
                        )
                )}
                
                <label htmlFor="inputName">Note :</label>
                    <input type="text" id='comment' className="form-control" name="comment" onChange={this.handleDate} placeholder="tulis pesan untuk orderan anda" value={this.state.comment}  />
            </form>


            <div className="form-group">
                <button  className="btn btn-success btn-lg btn-block" onClick={this.handleOpenModal} >Order</button>
                </div> 
                {this.modal}
            </div>


        )
    }
}

export default withRouter(LaundryPage);