import React, { Component } from "react";

class AboutPage extends Component{
    render(){
        return(
            <div className="order-form">
            <form>
                <h2>About</h2>
            <h6>
            <ul>
                M-Service hadir untuk memudahkan aktivitas harian Anda khususnya yang berdomisili di District 1, Meikarta, Lippo Cikarang. Beberapa layanan yang Kami hadirkan khusus untuk Anda antara lain :
            </ul>
            <ul>1.	Laundry Kiloan / Satuan </ul>
            <ul>2.	Catering Harian</ul>
            <ul>3.	Gas & Air Mineral Galon</ul>
            <ul>4.	Pengiriman Paket</ul>
            <ul>5.	Air Conditioner (AC)</ul>
            <ul>6.	Cleaning Service</ul>
            <ul>7.	Kesehatan (coming soon)</ul>
            <ul>8.	Car Wash (coming soon)</ul>
            <ul>9.	Rent Car (coming soon)</ul>
            <ul>10.	Akan Terus Dikembangakn</ul>
            
            <ul>
            Kami siap melayani Anda hingga di depan unit Anda langsung, lupakan repot – repot keluar dari unit Anda.
            </ul>
            <ul>
            Untuk informasi lebih lanjut hubungi Custome Service kami
            Di nomer WA : 0812-8109-4975
            Atau klik link : <a href="https://bit.ly/InfoLayananMService">https://bit.ly/InfoLayananMService</a>
            </ul>
            
            <ul>
            Terima Kasih
            </ul>
            </h6>
            </form>   
            </div>
        )
    }
}

export default AboutPage;