import React from "react";
import { Col, Card } from "react-bootstrap";
import NumberFormat from 'react-number-format';


const Cardfoodhall = (props)=>{
    return(
      
        <Col lg={2} md={4} xs={6} >
        <Card className="shadow"  style={{ width: '170px' }}>
          <Card.Img
            variant="top"
            src={
                props.item.picture
                // "https://drive.google.com/thumbnail?id=1WRaF6TGAaVY2VL-10jJOzOu3JQ1GfxeL"
              }
           
            height="170px"          />
          <Card.Body>
            <Card.Title>{props.item.item_name}</Card.Title>
            <Card.Text> <NumberFormat value={props.item.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /> </Card.Text>
            <button class="btn btn-primary" onClick={()=>props.masukankeranjang(props.item)}>+ tambahkan</button>
          </Card.Body>
        </Card>
      </Col>
      
    )
  }
            

export default Cardfoodhall
