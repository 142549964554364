import React, { Component } from 'react';
import axios from 'axios';
import './order.css';
import '../component/modalForm.css';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {converttorupiah} from '../component/atoms/convertRupiah'





class CleanPage extends Component{
     state={
         showModal: false,
        listcleans:{},
        delivdate:'',
        order:{},
        preorder:0,
        confirm:false,
        comment : '',
        totalharga:0
    }
   
   modal='ok'

    componentDidMount(){

        if (this.props.users.name === '' || this.props.users.name === undefined){
            alert('Silahkan login sebelum melakukan transaksi');
            const {history} = this.props
            history.push({
                pathname: '/login'
            });   
        }
        
        axios.get('/mteknik/list',{params:{service:'CS'}})
        .then((cleans)=>{
            this.setState({
                listcleans: cleans.data
            })
        })
        .finally(()=>{
        Object.entries(this.state.listcleans).map(
            ([key,value])=>(
               // console.log('ini valuenya ',value)
                this.setState(prevState =>({
                    order:{
                        ...prevState.order, [value.id] :0}
                }))
            )
        ) 
        
        console.log(this.state.listcleans)
    
    })
    } 

    handleOpenModal =() =>{
        this.setState({preorder:0})
        Object.entries(this.state.order).map(([key,value])=>(
            (value>=1)&&this.setState({preorder:1})
        ))
        this.setState(
            { showModal: true }
            );
      }

      handleCloseModal =() => {
        //  console.log('ini', this.state.showModal)
        this.setState({ showModal: false });
      }

      handleDelivDate = (e) =>{
          this.setState({
              delivdate: e.target.value
          })//,()=>console.log(this.state.delivdate))
          
          }



    
    handleChangeText = (e) =>{
        let order =[...this.state.order]
            order[[e.target.id]]= e.target.value
            this.setState({
                order
            })

        
        console.log(this.state.order)
    }

    handlePlus= (e)=>{
        let plus = this.state.order[e.target.id]+1
        let id = e.target.id
        this.setState(prevState=>({
            order:{...prevState.order,[id]:plus}
        })
        )
    }

    handleMinus=(e)=>{
        let minus = this.state.order[e.target.id]-1
        let id = e.target.id
        if(this.state.order[e.target.id] > 0){
        this.setState(prevState=>({
            order:{...prevState.order,[id]:minus}
        })
        )}
        
        //console.log(this.state.order)
    }

    finishOrder=()=>{
        const {history} = this.props
        let pesanan = ''
        pesanan += '%0Anama%20%3A%20'+ this.props.users.name
        pesanan += '%0Aunit%20%3A%20'+ this.props.users.address
        pesanan += '%0APhone%20%3A%20' + this.props.users.handphone
        pesanan += '%0Aitem order %20%3A%20'
        Object.entries(this.state.order).map((key,value)=>{
            if(key[1] > 0){
                //console.log('ini key :', key)
                Object.entries(this.state.listcleans)
                .filter(([kunci,isi]) => Number(isi.id) === Number(key[0]))
                .map((res)=>{
                //     console.log('ini res',res)
                pesanan +=  '%0A%20%20%20'+res[1].serviceItem+'%20%3A%20'+key[1]+' x '+converttorupiah(res[1].price)+' = '+converttorupiah(res[1].price*this.state.order[res[1].id])
                //     pesanan +=  '%0A%20%20%20'+res[1].serviceItem+'%20%3A%20'+key[1]
                }) 
            }
         })
         pesanan += '%0A%0ATotal Pembayaran%20%3A%20'+converttorupiah(this.state.totalharga)

         pesanan += '%0ANotes%20%3A%20'+this.state.comment

         pesanan += '%0A%0APembayaran dapat melalui transfer ke salah satu rekening berikut, '
         pesanan += '%0AA/N Latif Kurnia Desianto'
         pesanan += '%0ABCA: 0-111-766-478'
         pesanan += '%0ABNI: 0-434-237-380'
         pesanan += '%0AMANDIRI: 1-560-010-644-161'
         pesanan += '%0ANIAGA: 702-982-390-100'
         pesanan += '%0AJENIUS: 90-370-065-784'
         pesanan += '%0AOVO: 0856-5552-7783'
         
         pesanan += '%0A%0Abukti transfer dapat dikirim ke WA https://wa.me/6281281094975 '


        window.open("https://wa.me/+628161402805?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice."+
        pesanan+'%0Atolong%20segera%20di%20proses%20ya', '_blank', 'noopener,noreferrer')

        
        history.push({
            pathname: '/'
        });
    }

    handleText=(event)=>{
        //console.log('ini',event)
            this.setState({[event.target.id]: event.target.value})
     
    }

    submitOrder=(totharga)=>{
        this.setState({totalharga: totharga})
        //console.log(this.state.order)
        let parameter = {
            userid: this.props.users.uid,
            order : this.state.order ,
            comment : this.state.comment
            
        }
        axios.post('/mteknik/addTrans',parameter)
        .then(()=>{
        this.modal =
        <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Pemesanan sukses</h4>
                    </div>
                    <div className='modal-body'>
                        <div>
                        <span>
                            <label>Pemesanan anda telah masuk ke dalam database kami dan akan kami proses. 
                            </label>
                            <br/>
                            <label>Klik tombol dibawah ini untuk konfirmasi WA </label>
                            </span>
                        </div>
                      
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.finishOrder} className="btn btn-primary btn-lg btn-xs">Konfirm Whatsapp</button>
                    {/* <a href="https://wa.me/+6281281094975?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice.%20%0Atolong%20segera%20di%20proses%20ya.%20" className="btn btn-primary btn-lg btn-xs">Konfirm whatsapp</a> */}
                    </div>
                    </div>
                </div>
            </div>
            this.setState({confirm:true})
        })
        .catch((error)=>{
            console.log(error)
        })
        this.setState({showModal:false})
        console.log(this.modal)
        this.setState({showModal:true})
        
    }
    
    render(){
        if (this.state.showModal && this.state.preorder===0){
            //console.log(this.state.selectedSetrika)
            this.modal= <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                    </div>
                    <div className='modal-body'>
                        silahkan pilih paket terlebih dahulu          
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        }
        else if(this.state.showModal && !this.state.confirm) { 
            let totharga = 0 
            this.modal =    <div className = 'modal'>
                    <div className='modal-dialog'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                            </div>
                            <div className='modal-body'>
                                <div>
                                <span>
                                    <label>nama </label> <label>: {this.props.users.name}</label>
                                    </span><br/>
                                    <span>
                                    <label>handphone </label> <label>: {this.props.users.handphone}</label>
                                    </span><br/>
                                    <span>
                                    <label>Unit </label> <label>: {this.props.users.address}</label>
                                    </span><br/>
                                </div>
                                <div>
                                    <h4>Pesanan </h4>
                                    <font size="2">
                                    <table  className="table table-hover  table-sm" >
                                       
                                     {Object.entries(this.state.order).map((key,value)=>{
                                       if(key[1] > 0){
                                       return(
                                          // console.log(key[0],'-',key[1])
                                        <tbody>
                                            {Object.entries(this.state.listcleans)
                                            .filter(([kunci,isi]) => Number(isi.id) === Number(key[0]))
                                            .map((res)=>{
                                                totharga += res[1].price*this.state.order[res[1].id]
                                                return(
                                                    <tr>
                                                    <td class="align-middle" >{res[1].serviceItem}</td>
                                                    <td class="align-middle" > : </td>
                                                    <td class="align-middle" >{this.state.order[res[1].id]}</td>
                                                    <td class="align-middle" >*</td>
                                                    <td class="align-middle text-nowrap"  >{converttorupiah(res[1].price)}</td>
                                                    <td class="align-middle" > = </td>
                                                    <td class="align-middle text-nowrap"  >{converttorupiah(res[1].price*this.state.order[res[1].id])}</td>  
                                                </tr>
                                                //  <div>
                                                //      {console.log('ini result',res)}
                                                //      <div>{res[1].serviceItem + ' :' +this.state.order[res[1].id]}</div> 
                                                //      <br />
                                                //  </div>
    
                                             )})}
                                         </tbody>
                                       )
                                       
                                    }
                                    }
                                    )} 
                                    <tr>
                                                <td colSpan="4"> <h5> Total pembayaran </h5> </td> 
                                                <td colSpan="3" align="right"> <h5> {converttorupiah(totharga)} </h5> </td>
                                            </tr>
                                   </table>
                                        </font>   
                                        <div>Note : {this.state.comment}</div>
                                            <table className='table-sm'>
                                                <tr>
                                                <td colSpan="3">Pembayaran dapat melalui transfer ke rekening berikut,</td>
                                                </tr>
                                                <tr>
                                                    <td className='fw-bold'>A/N </td>
                                                    <td>:</td>
                                                    <td>Latif Kurnia Desianto</td>
                                                </tr>
                                                <tr>
                                                    <td >BCA </td>
                                                    <td>:</td>
                                                    <td>0-111-766-47</td>
                                                </tr>
                                                <tr>
                                                    <td >BNI </td>
                                                    <td>:</td>
                                                    <td>0-434-237-38</td>
                                                </tr>
                                                <tr>
                                                    <td >MANDIRI </td>
                                                    <td>:</td>
                                                    <td>1-560-010-644-16</td>
                                                </tr>
                                                <tr>
                                                    <td >NIAGA </td>
                                                    <td>:</td>
                                                    <td>702-982-390-10</td>
                                                </tr>
                                                <tr>
                                                    <td >JENIUS </td>
                                                    <td>:</td>
                                                    <td>90-370-065-78</td>
                                                </tr>
                                                <tr>
                                                    <td >OVO </td>
                                                    <td>:</td>
                                                    <td>0856-5552-778</td>
                                                </tr>
    
                                            </table>
                                </div>
    
                                
                            </div>
                            <div className='modal-footer'>
                            <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                            <button type='button' onClick={this.submitOrder.bind(this,totharga)} className="btn btn-primary btn-lg btn-xs">Confirm Pesanan</button>
                            </div>
                        </div>
                    </div>
                </div>
                }else if(this.state.showModal && this.state.confirm) {this.modal=this.modal }
    
                else{
                    this.modal =''
                }
    

            
        return(
            <div className="order-form">
            <form>
                <h2>Cleaning Service</h2>
                <p className="hint-text">Membersihkan ruangan siap huni anda</p>
                <div className="form-group">
                <div className="row">
                    <div className="col">Nama<input type="text" className="form-control" name="first_name" placeholder="Name" required="required" value={this.props.users.name} /></div>
                    <div className="col">No Handphone<input type="text" className="form-control" name="last_name" placeholder="phone" required="required" value={this.props.users.handphone} /></div>
                </div>        	
                </div>
                <div className="form-group">

                <label for="inputName">Unit</label>
                    <input type="text" className="form-control" name="unit" placeholder="unit" required="required" value={this.props.users.address} />
                </div>
      

                {/* <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="form-control input-group-text" >
                             Antar
                        </div>
                        </div>
                        <select className="form-control custom-select" id="inputGroupSelect01" onChange={this.handleDelivDate} >
                            <option selected>Jadwal Pengantaran </option>
                            <option value="1">HARI INI SIANG (11.00 ~ 12.00)</option>
                            <option value="2">HARI INI SORE (17.00 ~ 18.00)</option>
                            <option value="3">BESOK SIANG (11.00 ~ 12.00)</option>
                            <option value="4">BESOK SORE (17.00 ~ 18.00)</option>
                        </select>
                </div> */}
                
                
                <h2> Pesanan</h2>
                
                {Object.entries(this.state.listcleans)
                    .map(([key,value])=>(
                        
                        <div className='row form-group'>
                            <div className='col-md-4 '>   
                                <div className="form-group">
                                <label>{value.serviceItem}</label>
                                <NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /> 
                                </div>
                            </div>
                            <div className='col-md-6'> 
                            
                                <div className="input-group counter " >
                                    <button id={value.id} type="button" className="btn btn-info btn-sm" onClick={this.handleMinus}>-</button>
                                    <input id={value.id} onChange={this.handleChangeText} type="text" className="form-control" value={this.state.order[value.id]} size='1' />
                                    <button id={value.id} type="button" className="btn btn-info btn-sm" onClick={this.handlePlus}>+</button>
                                </div>
                            </div>
                        </div>

                    ))
                }

                    <label for="inputName">Note :</label>
                    <input type="text" id='comment' className="form-control" name="comment" onChange={this.handleText} placeholder="tulis pesan untuk orderan anda" value={this.state.comment}  />

                                            
            </form>   
               
                                
                <div className="form-group">
                <button onClick={this.handleOpenModal} className="btn btn-success btn-lg btn-block">Pesan</button>
                </div>



            {this.modal}

            </div>

        )
    }
}

export default withRouter(CleanPage);