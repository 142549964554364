import React, { Component } from 'react'; 

import {connect } from 'react-redux';



class  MenuAdminForm  extends Component {
    state ={
        user:{}
    }

   //  componentDidMount(){
   //     axios.get('/api')
   //     .then((res)=>{
   //         console.log(res);
   //     })
   //  }

   render(){
       
    return(
    
           <section id="topbar2" className="fixed-top d-flex align-items-center">
               <div className="container d-flex justify-content-center justify-content-between">
                   <nav id="navbar2" className="navbar">
                   <ul>
                      
                        <li class="dropdown"><a href="#"><span>transaksi</span> <i class="bi bi-chevron-down"></i></a>
                            <ul>
                            <li><a href="#">Drop Down 1</a></li>
                            <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                                <ul>
                                <li><a href="#">Deep Drop Down 1</a></li>
                                <li><a href="#">Deep Drop Down 2</a></li>
                                <li><a href="#">Deep Drop Down 3</a></li>
                                <li><a href="#">Deep Drop Down 4</a></li>
                                <li><a href="#">Deep Drop Down 5</a></li>
                                </ul>
                            </li>
                            <li><a href="#">Drop Down 2</a></li>
                            <li><a href="#">Drop Down 3</a></li>
                            <li><a href="#">Drop Down 4</a></li>
                            </ul>
                        </li>

                       <li><a className="nav-link scrollto" href="/order/admin/upload">Upload Cathering</a></li>
                       
                       
                   </ul>
                   <i className="bi bi-list mobile-nav-toggle" />
                   </nav>
               </div>
           </section>
       
           
    )
   }
}
const reduxState = (state) =>({
   userName : state.user
})

export default connect(reduxState, null)(MenuAdminForm);