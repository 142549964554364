import React, { Component } from 'react';
import axios from 'axios';
import './order.css';
import '../component/modalForm.css';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

class CatheringPage extends Component{
    state={
        showModal: false,
        listfood:{},
        pickdate:'',
        delivdate:'',
        order:{},
        confirm:false,
        preorder:0,
        hari:['senin','selasa','rabu','kamis','jumat','sabtu','minggu'],
        comment : ''
   }
    

   componentDidMount(){

    if (this.props.users.name === '' || this.props.users.name === undefined){
        alert('Silahkan login sebelum melakukan transaksi');
        const {history} = this.props
        history.push({
            pathname: '/login'
        });   
    }

    axios.get('/cathering/list')
    .then((foodlist)=>{
         this.setState({
             listfood: foodlist.data
         })        
    })
    .finally(()=>{
        Object.entries(this.state.listfood).map(
            ([key,value])=>(
               // console.log('ini valuenya ',value)
                this.setState(prevState =>({
                    order:{
                        ...prevState.order, [value.id] :0}
                }))
            )
        )        
    
    })

} 


    handleOpenModal =() =>{
        this.setState({preorder:0})
        Object.entries(this.state.order).map(([key,value])=>(
            (value>=1)&&this.setState({preorder:1})
        ))

        this.setState(
            { showModal: true }
            );
      }

    handleCloseModal =() => {
        // console.log('ini', this.state.showModal)
        this.setState({ showModal: false });
    }

    handlePlus= (e)=>{
        let plus = this.state.order[e.target.id]+1
        this.setState(prevState=>({
            order:{...prevState.order,[e.target.id]:plus}
        })
        )
    }

    handleMinus=(e)=>{
        let minus = this.state.order[e.target.id]-1
        if(this.state.order[e.target.id] > 0){
        this.setState(prevState=>({
            order:{...prevState.order,[e.target.id]:minus}
        })
        )}
        
        //console.log(this.state.order)
    }
    handleText=(event)=>{
        //console.log('ini',event)
        
            this.setState({[event.target.id]: event.target.value})
     
    }

    finishOrder=()=>{
        const {history} = this.props
        let pesanan = ''
        pesanan += '%0Anama%20%3A%20'+ this.props.users.name
        pesanan += '%0Aunit%20%3A%20'+ this.props.users.address
        pesanan += '%0APhone%20%3A%20' + this.props.users.handphone
        pesanan += '%0Aitem order : %20%3A%20'
        Object.entries(this.state.order).map((key,value)=>{
            if(key[1] > 0){
                //console.log('ini key :', key)
                Object.entries(this.state.listfood)
                .filter(([kunci,isi]) => Number(isi.id) === Number(key[0]))
                .map((res)=>{
                //     console.log('ini res',res)
                     pesanan +=  '%0A%20%20%20'+res[1].cday + ', ' +res[1].ctime+'%20%3A%20'+key[1]
                }) 
            }
         })
         pesanan += '%0ANotes%20%3A%20'+this.state.comment

        window.open("https://wa.me/+6281281094975?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice."+
        pesanan+'%0Atolong%20segera%20di%20proses%20ya', '_blank', 'noopener,noreferrer')
        
        history.push({
            pathname: '/'
        });
    }

    submitOrder=()=>{
        let parameter ={
            userid : this.props.users.uid,
            order: this.state.order,
            comment: this.state.comment

        }
        
        axios.post('/cathering/addTrans',parameter)
        .then(()=>{
        this.modal =
        <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Pemesanan sukses</h4>
                    </div>
                    <div className='modal-body'>
                        <div>
                        <span>
                            <label>Pemesanan anda telah masuk ke dalam database kami dan akan kami proses. 
                            </label>
                            <br/>
                            <label>Klik tombol dibawah ini untuk konfirmasi WA </label>
                            </span>
                        </div>
                      
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.finishOrder} className="btn btn-primary btn-lg btn-xs">Konfirm Whatsapp</button>
                    {/* <a href={"https://wa.me/+6281281094975?text=Hi%20kakak%2C%20saya%20sudah%20pesan%20melalui%20web%20mservice.%0Anama%20%3A%20"+
                            this.props.users.name+"%0Atolong%20segera%20di%20proses%20ya.%20"} 
                            className="btn btn-primary btn-lg btn-xs">Konfirm whatsapp
                    </a> */}
                    </div>
                    </div>
                </div>
            </div>
            this.setState({confirm:true})
        })
        .catch((error)=>{
            console.log(error)
        })
        this.setState({showModal:false})
        console.log(this.modal)
        this.setState({showModal:true})

    }

    render(){   


        if (this.state.showModal && this.state.preorder===0){
            //console.log(this.state.selectedSetrika)
            this.modal= <div className = 'modal'>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                    </div>
                    <div className='modal-body'>
                        silahkan pilih paket makanan terlebih dahulu          
                    </div>
                    <div className='modal-footer'>
                    <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        }
        else if(this.state.showModal && !this.state.confirm) { 
            this.modal =    <div className = 'modal'>
                    <div className='modal-dialog'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h4 className='modal-title'>Konfirmasi Pesanan</h4>
                            </div>
                            <div className='modal-body'>
                                <div>
                                <span>
                                    <label>nama </label> <label>: {this.props.users.name}</label>
                                    </span><br/>
                                    <span>
                                    <label>handphone </label> <label>: {this.props.users.handphone}</label>
                                    </span><br/>
                                    <span>
                                    <label>Unit </label> <label>: {this.props.users.address}</label>
                                    </span><br/>
                                </div>
                                <div>
                                    <h4>Pesanan </h4>
                                       
                                    {Object.entries(this.state.order).map((key,value)=>{
                                       if(key[1] > 0){
                                       return(
                                          // console.log(key[0],'-',key[1])
                                        <div>
                                            {Object.entries(this.state.listfood)
                                            .filter(([kunci,isi]) => Number(isi.id) === Number(key[0]))
                                            .map((res)=>{return(
                                                 <div>
                                                     {console.log('ini result',res)}
                                                     <div>{res[1].cday + ' ,' +res[1].ctime+' : '+key[1]}</div> 
                                                     <br />
                                                 </div>
    
                                             )})}
                                             
                                         </div>
                                       )
                                       
                                    }
                                    }
                                    )}
                                    <span>
                                        Note : {this.state.comment}
                                    </span>
                                </div>
    
                                
                            </div>
                            <div className='modal-footer'>
                            <button type='button' onClick={this.handleCloseModal} className="btn btn-primary btn-lg btn-xs">Cancel</button>
                            <button type='button' onClick={this.submitOrder} className="btn btn-primary btn-lg btn-xs">Confirm Pesanan</button>
                            </div>
                        </div>
                    </div>
                </div>
                }else if(this.state.showModal && this.state.confirm) {this.modal=this.modal }
    
                else{
                    this.modal =''
                }
    

        return(
            <div className="order-form">
            <form  method="post">
                <h2>Catering order</h2>
                <p className="hint-text">Perut kenyang hati senang</p>
                <div className="form-group">
                <h2>Data pengirim</h2>
                <div className="row">
                    <div className="col">Nama<input type="text" className="form-control" name="first_name" placeholder="Name" required="required" value={this.props.users.name} /></div>
                    <div className="col">No Handphone<input type="text" className="form-control" name="last_name" placeholder="phone" required="required" value={this.props.users.handphone} /></div>
                </div>        	
                </div>
                <div className="form-group">

                <label for="inputName">unit</label>
                    <input type="text" className="form-control" name="email" placeholder="address" required="required" value={this.props.users.address} />
                </div>

                {this.state.hari.map((key)=>(
                    //console.log(key)
                     <div className='order-day '>
                         <h2>{key}</h2> 
                         <div className='row'>
                         {Object.entries(this.state.listfood)
                         .filter(([kunci,isi])=>isi.cday.toLowerCase()=== key)
                         .map(([keys,value])=>{ 
                           return(
                               <div className="col-md-4 ">
                                   {console.log(value)}
                                    <div className="order border-left-primary shadow h-100 py-2"> 
                                    <div className="card-body">
                                        <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                        <img className="image-product" src="../asset/cathering/cathering.png" alt="product" />
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{value.ctime} 
                                            <h6>
                                            <div>{value.cname}</div>
                                            </h6>
                                            <NumberFormat value={value.cprice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /> 
                                            
                                            </div>
                                            
                                        </div>
                                        <div className="input-group input-group-sm">
                                                <span className="input-group-append">
                                                    <button id={value.id} type="button" className="btn btn-info btn-flat" onClick={this.handleMinus}>-</button>
                                                </span>
                                                <input id={value.id} type="text" className="form-control" value={this.state.order[value.id]} />
                                                <span className="input-group-append">
                                                    <button id={value.id} type="button" className="btn btn-info btn-flat" onClick={this.handlePlus}>+</button>
                                                </span>
                                        </div>
                                        </div>
                                    </div>
                                    </div> 
                                </div>
                           

                         )})  }

                        </div>   
                    </div>

                ))
                }

                    <label for="inputName">Note :</label>
                    <input type="text" id='comment' className="form-control" name="comment" onChange={this.handleText} placeholder="tulis pesan untuk orderan anda" value={this.state.comment}  />

                </form>  
                

                <div className="form-group">
                <button type="submit" className="btn btn-success btn-lg btn-block" onClick={this.handleOpenModal} >Pesan</button>
                </div>

                {this.modal}
                
            </div>

        )
    }
}

export default withRouter(CatheringPage);