import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y , Autoplay} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css';
import ads1 from './img/ads11.jpg'
import ads2 from './img/furnicreabanner.jpg'
import ads3 from './img/adsSpace.jpg'
import ads4 from './img/banner3.jpg'




SwiperCore.use([Autoplay])

const Carousel = () => (
  
    <Swiper
    loop={true}
    autoplay={{delay: 3000}}
    modules={[Navigation, Pagination, Scrollbar, A11y]}
    spaceBetween={50}
    slidesPerView={1}
    navigation
    pagination={{ clickable: true }}
    scrollbar={{ draggable: true }}
    onSwiper={(swiper) => console.log(swiper)}
    onSlideChange={() => console.log('slide change')}
    >

  <SwiperSlide><img width="100%"  src={ads1}></img></SwiperSlide>
  <SwiperSlide>
    <a href='https://wa.me/+628161402805?text=Hi%20kakak%2C%20tolong%20mau%20tanya%20mengenai%20furnicrea%20donk'>
    <img width="100%"  src={ads2}></img></a></SwiperSlide>
  <SwiperSlide>
  <a href='https://wa.me/+6281281094975?text=Hi%20kakak%2C%20tolong%20informasi%20untuk%20pasang%20iklan%20di%20situs%20Mservice%20'>
    <img width="100%"  src={ads3}></img></a></SwiperSlide>
    <SwiperSlide>
  <a href='https://wa.me/+6281281094975?text=Hi%20kakak%2C%20tolong%20informasi%20untuk%20pesan%20tiket%20di%20renang%20Mservice%20'>
    <img width="100%"  src={ads4}></img></a></SwiperSlide>
    {/* <SwiperSlide><img width="100%"  src='https://1.bp.blogspot.com/-CUEwtCexAEU/Xy1_IJQ_nzI/AAAAAAAAGB8/Seee213hjxsS-mJOugH9Glp-DmKkwiZcACLcBGAsYHQ/s700/Contoh%2BIklan%2BNiaga%2BAdalah.jpg'></img></SwiperSlide>
    <SwiperSlide><img width="100%"  src='https://1.bp.blogspot.com/-CUEwtCexAEU/Xy1_IJQ_nzI/AAAAAAAAGB8/Seee213hjxsS-mJOugH9Glp-DmKkwiZcACLcBGAsYHQ/s700/Contoh%2BIklan%2BNiaga%2BAdalah.jpg'></img></SwiperSlide>
    <SwiperSlide><img width="100%"  src='https://1.bp.blogspot.com/-CUEwtCexAEU/Xy1_IJQ_nzI/AAAAAAAAGB8/Seee213hjxsS-mJOugH9Glp-DmKkwiZcACLcBGAsYHQ/s700/Contoh%2BIklan%2BNiaga%2BAdalah.jpg'></img></SwiperSlide> */}
  </Swiper>
)
 
export default Carousel;