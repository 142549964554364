import React, { Component, Fragment } from 'react';
import axios from 'axios';
import './order.css';
import '../component/modalForm.css';
import MenuAdminForm from '../component/menuadmin';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import date from 'date-and-time';
import { Table } from 'react-bootstrap';



class AdminNewPage extends Component{
     state={
         showModal: false,
        listtrans:{},
        delivdate:'',
        order:[],
        confirm:false,
        fromdate:'',
        todate: '',
        typetrans:''
    } 
   modal='ok'

    componentDidMount(){
        
        // if (this.props.users.handphone !== '' || this.props.users.handphone !== undefined){
        //     alert('admin harus login terlebih dahulu');
        //     const {history} = this.props
        //     history.push({
        //         pathname: '/login'
        //     });   
        // }
        
        axios.get('/order/admin/transDelivery')
        .then((trans)=>{
            //console.log('ini adalah',trans)
             this.setState({
                 listtrans: trans.data
             })  
        })

        
    } 

    handleOpenModal =() =>{
        this.setState(
            { showModal: true }
            );
      }

      handleCloseModal =() => {
          console.log('ini', this.state.showModal)
        this.setState({ showModal: false });
      }

      handleDelivDate = (e) =>{
          this.setState({
              delivdate: e.target.value
          })//,()=>console.log(this.state.delivdate))
          
          }
          
      

    



    
    handleChangeText = (e) =>{
            this.setState({
                [e.target.id]:e.target.value
            })
        //console.log(this.state.fromdate,'----',this.state.typetrans)
        }
   

    HandleFilter=()=>{
        if(this.state.fromdate < this.state.todate) { 
            let parameter = {fromdate : this.state.fromdate, todate: this.state.todate}
            //console.log('params ', parameter)
            axios.get('/order/admin/transDelivery', {params:parameter})
                .then((trans)=>{
                console.log('ini adalah',trans)
                 this.setState({
                     listtrans: trans.data
                 })  
            })
        }else{ console.log('salah')}
    }

    convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }

    gettransdate=(tanggal)=>{
        let newdate = new Date(tanggal);
        // newdate= this.convertTZ(newdate, "Asia/Jakarta")
        // newdate.setHours(newdate.getHours())
        //console.log(newdate)
        return date.format(newdate,'YYYY-MM-DD HH:mm:ss')
    }

    setDateRange=(update)=>{
        this.setState({fromdate:update[0]})
        this.setState({todate:update[1]})
    }
   

    
    
    render(){   

        return(
            <div>
           
            {/* <MenuAdminForm/>
            <div className="input-daterange input-group" id="datepicker">
            <input type="text" className="input-sm form-control" name="start" />
            </div> */}

            
                {/* <section className="content"> */}
                <div className="row">

                    <div className="col-12">
                    <div>
                        <div className="card-header">
                        <h3 className="card-title">Transaction Delivery</h3>
                        </div>
                        <div className="row form-group">
                        <div className=" col-4">
                         <label>Date range:</label>  
                         </div>
                                {/* <div className='col-4'> <DatePicker selected={this.state.fromdate}  onChange={(date) => this.setState({fromdate:date})} /> </div> 
                                <div className='col-1'> to </div>
                                <div className='col-4'><DatePicker selected={this.state.todate}  onChange={(date) => this.setState({todate:date})} /> </div> */}
                         <div className='col-4'>       <DatePicker
                                    todayButton="Today"
                                    selectsRange={true}
                                    startDate={this.state.fromdate}
                                    endDate={this.state.todate}
                                    onChange={(update) => {
                                        this.setDateRange(update);
                                    }}
                                    />
                            </div>
                            <div className='col-4'> 
                                <button type="submit" className="btn btn-success btn-block btn-sm" onClick={this.HandleFilter} >filter</button>
                        
                    
                    </div>
                    

                    {/* <div className="form-group col-md-1">

                    <label>type transaction</label>
                            <div className="input-group">
                                <select class="" id="typetrans"  onChange={this.handleChangeText}  >
                                <option default value="all">All transaction</option>
                                <option value="gasmin">Gas Mineral</option>
                                <option value="laundry">Laundry</option>
                                <option value="cathering">Cathering</option>
                                <option value="delivery">Delivery</option>
                                </select>
                                <div>
                    </div>
                            </div>
                    </div>
                    */}


                    <div className="form-group col-md-6">
                        <label></label>
                            <div className="input-group">
                            
                            </div>
                    </div>

                    </div>

                    
                       
                        <Table striped bordered hover size="sm">
                            <thead>
                            <tr>
                                <th>trans_id</th>
                                <th>transdate</th>
                                <th>pickupdate</th>
                                <th>name</th>
                                <th>handphone</th>
                                <th>unit</th>
                                <th>penerima</th>
                                <th>phone</th>
                                <th>alamat</th>
                                <th>kecamatan</th>
                                <th>kabupaten</th>
                                <th>kodepos</th>
                                <th>isi_paket</th>
                                <th>jumlah</th>
                                <th>harga</th>
                                <th>Notes</th>
                               
                                </tr>
                            </thead>
                            <tbody>
                            {Object.entries(this.state.listtrans)
                            .map(([key,value])=>(
                                    <tr>
                                        {this.tanggal = value.transdate}
                                        <td>{value.transid}</td>
                                        <td>{this.gettransdate(value.transDate)}</td>
                                        <td>{this.gettransdate(value.pickupDate)}</td>
                                        <td>{value.name}</td>
                                        <td>{value.handphone}</td>
                                        <td>{value.unit}</td>
                                        <td>{value.penerima}</td>
                                        <td>{value.phone}</td>
                                        <td>{value.alamat}</td>
                                        <td>{value.kecamatan}</td>
                                        <td>{value.kabupaten}</td>
                                        <td>{value.kodepos}</td>
                                        <td>{value.isi_paket}</td>
                                        <td>{value.jumlah}</td>
                                        <td>{value.harga}</td>
                                        <td>{value.notes}</td>
                                    </tr>
                                        
      
                                )
                        )}
                            
                            </tbody>
                            
                        </Table>
                        
                    </div>
                    </div>
                </div>
                    {/* </section> */}

                  
            </div>
        )
    }
}

export default AdminNewPage;