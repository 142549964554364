import React, { Component } from 'react'; 
import Logo from "../Logo.jpeg"; 
import brocure from "../LayananMService.pdf";
import {connect } from 'react-redux';




 class  MenuForm  extends Component {
     state ={
         user:{}
     }

    render(){
        
        
        let link = ""
        let welcome = ""
        if (this.props.name){
            link ="logout"
            welcome = "  Hi, "+this.props.name
        } else{
            link = "login"
            welcome = "login"
        }
     return(
     
            <section id="topbar" className="fixed-top d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-between">
                    <div className="logo">
                    <h1><a href="index.html"><img src={Logo} height="40dp" /></a></h1>
                    </div>
                    <nav id="navbar1" className="navbar1">
                    <ul>
                       
                        <li><a className="nav-link scrollto" href="/">Product</a></li>
                        <li><a className="nav-link scrollto" href={brocure}>E-brocure</a></li>
                        <li><a className="nav-link scrollto " href="/Promo">Promo</a></li>
                        <li><a className="nav-link scrollto" href="/about">About</a></li>
                        <li><a className="nav-link scrollto" href={"/"+link}>{welcome}</a></li>
                        
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle1" />
                    </nav>
                </div>
            </section>
        
            
     )
    }
 }
 const reduxState = (state) =>({
    userName : state.user
})

 export default connect(reduxState, null)(MenuForm);