import React, { Component, Fragment } from 'react';
import axios from 'axios';
import './order.css';
import '../component/modalForm.css';
import MenuAdminForm from '../component/menuadmin';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import date from 'date-and-time';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTrashCan } from '@fortawesome/free-solid-svg-icons'



class AdminPage extends Component{
    state={
        showModal: false,
       listtrans:{},
       delivdate:'',
       order:[],
       confirm:false,
       fromdate:'',
       todate: '',
       typetrans:'',
       vendor:''
   } 
  modal='ok'

   componentDidMount(){
       
       // if (this.props.users.handphone !== '' || this.props.users.handphone !== undefined){
       //     alert('admin harus login terlebih dahulu');
       //     const {history} = this.props
       //     history.push({
       //         pathname: '/login'
       //     });   
       // }
       Swal.fire({
           title: 'Loading data ',
           html: 'please wait ..',
           timerProgressBar: true,
           didOpen: () => {
               Swal.showLoading()
               let parameter = {fromdate : new Date(), todate: new Date(), vendor:'all'}
               console.log(parameter)
               axios.get('/order/admin/transaction2',{params:parameter})
               .then((trans)=>{
                   //console.log('ini adalah',trans)
                   this.setState({
                       listtrans: trans.data
                   })  
                   Swal.close()
               })
             
           }})
       
       // axios.get('/order/admin/alltransaction2')
       // .then((trans)=>{
       //     //console.log('ini adalah',trans)
       //      this.setState({
       //          listtrans: trans.data
       //      })  
       // })

       
   } 

   handleOpenModal =() =>{
       this.setState(
           { showModal: true }
           );
     }

     handleCloseModal =() => {
         console.log('ini', this.state.showModal)
       this.setState({ showModal: false });
     }

     handleDelivDate = (e) =>{
         this.setState({
             delivdate: e.target.value
         })//,()=>console.log(this.state.delivdate))
         
         }

   handleChangeText = (e) =>{
           this.setState({
               [e.target.id]:e.target.value
           })
       //console.log(this.state.fromdate,'----',this.state.typetrans)
       }
  

   HandleFilter=()=>{
       if(this.state.fromdate <= this.state.todate) { 
           let parameter = {fromdate : this.state.fromdate, todate: this.state.todate,vendor:'all'}
           Swal.fire({
               title: 'Loading data ',
               html: 'please wait ..',
               timerProgressBar: true,
               didOpen: () => {
                   Swal.showLoading()
                   console.log(parameter)
                   axios.get('/order/admin/transaction2',{params:parameter})
                   .then((trans)=>{
                       //  console.log('ini adalah',trans)
                       this.setState({
                           listtrans: trans.data
                       })  
                       Swal.close()
                   })
                 
               }})
           // console.log('params ', parameter)
           // axios.get('/order/admin/transaction2', {params:parameter})
           //     .then((trans)=>{
           //     console.log('ini adalah',trans)
           //      this.setState({
           //          listtrans: trans.data
           //      })  
           // })
       }else{ console.log('salah')}

   }

   getzonedate=(tanggal)=>{
       if (tanggal === null){
           return null
       } else{
       let newdate = new Date(tanggal);
       newdate.setHours(newdate.getHours())
       //console.log(newdate)
       return date.format(newdate,'YYYY-MM-DD HH:mm:ss')
   }
   }

   delivpickdate=(tanggal)=>{
       if (tanggal === null){
           return null
       } else{
       let newdate = new Date(tanggal);
       //console.log(newdate)
       return  newdate.toISOString().replace('Z', ' ').replace('T', ' ').replace('.000','')
       
   }
   }

   setDateRange=(update)=>{
       this.setState({fromdate:update[0]})
       this.setState({todate:update[1]})
   }
  

   
   
   render(){   

       return(
           <div class="d-flex justify-content-center">
          
           {/* <MenuAdminForm/>
           <div className="input-daterange input-group" id="datepicker">
           <input type="text" className="input-sm form-control" name="start" />
           </div> */}

           
               {/* <section className="content"> */}
               <div className="row" >
                   <div className="col-12">
                   <div>
                       <div className="card-header">
                           <h3 className="card-title">Transaction</h3>
                       </div>
                       <div className="row m-2 justify-content-md-end">
                               
                               <div class="col col-md-auto">
                                   <label>Date range:</label>  
                               </div>
                                   {/* <div className='col-4'> <DatePicker selected={this.state.fromdate}  onChange={(date) => this.setState({fromdate:date})} /> </div> 
                                   <div className='col-1'> to </div>
                                   <div className='col-4'><DatePicker selected={this.state.todate}  onChange={(date) => this.setState({todate:date})} /> </div> */}
                               <div class="col col-md-auto">       
                                   <DatePicker
                                       todayButton="Today"
                                       selectsRange={true}
                                       startDate={this.state.fromdate}
                                       endDate={this.state.todate}
                                       onChange={(update) => {
                                           this.setDateRange(update);
                                       }}
                                       />
                               </div>  
                               <div class="col col-md-auto "> 
                                   <button type="submit" className="btn btn-success btn-block btn-sm" onClick={this.HandleFilter} >filter</button>
                               </div>
                   

                   {/* <div className="form-group col-md-1">

                   <label>type transaction</label>
                           <div className="input-group">
                               <select class="" id="typetrans"  onChange={this.handleChangeText}  >
                               <option default value="all">All transaction</option>
                               <option value="gasmin">Gas Mineral</option>
                               <option value="laundry">Laundry</option>
                               <option value="cathering">Cathering</option>
                               <option value="delivery">Delivery</option>
                               </select>
                               <div>
                   </div>
                           </div>
                   </div>
                   */}


                   

                   </div>

                   <div>
                      
                       <Table striped bordered hover size="sm"  >
                           
                   
                           <thead>
                           <tr>
                               <th>trans_id</th>
                               <th>transdate</th>
                               <th>name</th>
                               <th>handphone</th>
                               <th>unit</th>
                               <th>category</th>
                               <th>pickupdate</th>
                               <th>deliverydate</th>
                               <th>item</th>
                               <th>quantity</th>
                               <th>price</th>
                               <th>notes</th>
                               
                               {/* kasih harga dasar */}
                               {/* <th>action</th> */}
                               
                               </tr>
                           </thead>
                           {/* {console.log('trans',this.state.listtrans)} */}
                           <tbody className='justify-content-center'>
                           {Object.entries(this.state.listtrans)
                           .map(([key,value])=>(
                                   <tr>
                                       {this.tanggal = value.transdate}
                                       <td><small>{(value.transid).substr(value.transid.length - 5)}</small></td>
                                       <td><small>{this.delivpickdate(value.transDate)}</small></td>
                                       <td><small>{value.name}</small></td>
                                       <td><small>{value.handphone}</small></td>
                                       <td><small>{value.unit}</small></td>
                                       <td><small>{value.category}</small></td>
                                       <td><small>{this.delivpickdate(value.pickupDate)}</small></td>
                                       <td><small>{this.delivpickdate(value.deliveryDate)}</small></td>
                                       <td><small>{value.item}</small></td>
                                       <td><small>{value.quantity}</small></td>
                                       <td><small>{value.itemprice}</small></td>
                                       <td><small>{value.notes}</small></td> 
                                       {/* <td><h5><a href='#'><FontAwesomeIcon icon={faPaperPlane} /></a> <a href='#'><FontAwesomeIcon icon={faTrashCan} /></a></h5></td> */}
                                   </tr>
                                       
     
                               )
                       )}
                           
                           </tbody>
                               
                       </Table>
                       </div>
                   </div>
                   </div>
               </div>
                   {/* </section> */}

                 
           </div>
       )
   }
}

export default AdminPage;